import { inject, type InjectionKey } from 'vue';

import type { COLOR_PICKER_MODE_TYPE, GradientColor, GradientPoint } from './types';

import { Color } from './Color';
import { lightenDarkenColor, ID, isColor } from '@gem/common';
import { MAX_COLOR_SIZE, SUGGESTED_COLOR_FOR_YOU } from './hooks/useMyColor';
import type { ChangeInfo } from '@gem/control-v2';

export const TRANSPARENT_COLOR = 'transparent';
export const GRADIENT_KEY_WORD = 'linear-gradient';
export const DEFAULT_START_GRADIENT_COLOR = '#3C67FF';
export const GRADIENT_TAB = 'gradient' as const;
export const SOLID_TAB = 'solid' as const;
export const TABS = [SOLID_TAB, GRADIENT_TAB] as const;
export const DEFAULT_GRADIENT_BORDER_COLOR = '#757575';

export const getBackgroundStyle = (color?: string) => {
  if (color && color.toLowerCase().startsWith(GRADIENT_KEY_WORD)) {
    return {
      backgroundImage: color,
      borderColor: DEFAULT_GRADIENT_BORDER_COLOR,
    };
  }

  return {
    backgroundColor: color,
    borderColor: lightenDarkenColor(color!),
  };
};

export const checkIsColorGradient = (color: string) => {
  return typeof color === 'string' && color.toLowerCase().includes(GRADIENT_KEY_WORD);
};

export const toGradientObject = (gradientStr: string | undefined) => {
  gradientStr = gradientStr?.toLowerCase();
  if (!gradientStr || typeof gradientStr !== 'string' || !checkIsColorGradient(gradientStr)) {
    return;
  }

  gradientStr = gradientStr.replaceAll(`${GRADIENT_KEY_WORD}(`, '').slice(0, -1);
  const gradientArray = gradientStr.split('deg,');
  const gradientColor: GradientColor = {
    angle: parseInt(gradientArray[0]),
    points: new Map<string, GradientPoint>(),
  };
  gradientArray.shift();
  gradientArray[0].split('%,').forEach((color) => {
    color = color.replace('%', '');
    const lastSpaceIndex = color.lastIndexOf(' ');
    const colorString = color.slice(0, lastSpaceIndex);
    const position = parseInt(color.slice(lastSpaceIndex + 1));

    gradientColor.points.set(ID(), {
      color: Color.fromString(`${colorString})`.trim()),
      position: position,
    });
  });

  return gradientColor;
};

export const toGradientString = (gradientColor: GradientColor) => {
  const orderedPoints = Array.from(gradientColor.points, ([_, value]) => value).sort((a, b) => a.position - b.position);

  return `${GRADIENT_KEY_WORD}(${gradientColor.angle}deg, ${orderedPoints
    .map((item) => `${item.color.getColor()} ${item.position}%`)
    .join(', ')})`;
};

export const strictInject = <T>(key: InjectionKey<T>, defaultValue?: T) => {
  const resolved = inject(key, defaultValue);
  if (!resolved) {
    throw new Error(`Could not resolve ${key}`);
  }

  return resolved;
};

export const generateDefaultGradient = (firstPointID: string, mainColor: string): GradientColor => {
  // end color default white
  const endColor = Color.fromString('#FFFFFF');

  return {
    angle: 130,
    points: new Map([
      [
        firstPointID,
        {
          color: Color.fromString(mainColor),
          position: 0,
        },
      ],
      [
        ID(),
        {
          color: endColor,
          position: 100,
        },
      ],
    ]),
  };
};

export const generateNewGlobalStyleColor = (
  newColor: string | undefined,
  sourceColor: string[],
  maxSize: number,
  changeInfo: ChangeInfo,
) => {
  if (!newColor) return {};

  if (
    sourceColor.includes(newColor) ||
    SUGGESTED_COLOR_FOR_YOU.includes(newColor) ||
    !isColor(newColor) ||
    newColor === TRANSPARENT_COLOR
  ) {
    return changeInfo.colorKeys.reduce((acc, key) => {
      return {
        ...acc,
        [key]: sourceColor,
        [changeInfo.changeIndexKey]: 0,
      };
    }, {});
  }

  const newColorList = [...sourceColor].slice(0, MAX_COLOR_SIZE - 1);
  newColorList.unshift(newColor);

  return changeInfo.colorKeys.reduce((acc, key) => {
    return {
      ...acc,
      [key]: newColorList,
      [changeInfo.changeIndexKey]: 0,
    };
  }, {});
};

export const getBottomPosition = (
  mode: COLOR_PICKER_MODE_TYPE,
  isOpenColorPicker: boolean,
  activeTab: string,
  myColorsLength: number,
  recentGradientColorsLength: number,
) => {
  const defaultStyle = '!fixed !top-[unset]';

  if (mode === 'solid') {
    if (isOpenColorPicker) {
      return `${defaultStyle} !bottom-[0px]`;
    } else {
      return `${defaultStyle} !bottom-[203px]`;
    }
  }

  if (activeTab === SOLID_TAB) {
    if (recentGradientColorsLength > MAX_COLOR_SIZE / 2) {
      if (myColorsLength > MAX_COLOR_SIZE / 2) {
        if (isOpenColorPicker) {
          return `${defaultStyle} !bottom-[8px]`;
        } else {
          return `${defaultStyle} !bottom-[211px]`;
        }
      } else {
        if (isOpenColorPicker) {
          return `${defaultStyle} !bottom-[44px]`;
        } else {
          return `${defaultStyle} !bottom-[247px]`;
        }
      }
    } else {
      if (myColorsLength > MAX_COLOR_SIZE / 2) {
        if (isOpenColorPicker) {
          return `${defaultStyle} !bottom-[0px]`;
        } else {
          return `${defaultStyle} !bottom-[203px]`;
        }
      } else {
        if (isOpenColorPicker) {
          return `${defaultStyle} !bottom-[8px]`;
        } else {
          return `${defaultStyle} !bottom-[211px]`;
        }
      }
    }
  }

  if (recentGradientColorsLength > MAX_COLOR_SIZE / 2) {
    return `${defaultStyle} !bottom-16`;
  } else if (recentGradientColorsLength > 0) {
    if (myColorsLength > MAX_COLOR_SIZE / 2) {
      return `${defaultStyle} !bottom-[24px]`;
    } else {
      return `${defaultStyle} !bottom-[0px]`;
    }
  } else {
    if (myColorsLength > MAX_COLOR_SIZE / 2) {
      return `${defaultStyle} !bottom-[88px]`;
    } else {
      return `${defaultStyle} !bottom-[60px]`;
    }
  }
};

export const getMaxModalHeight = (myColorsLength: number, recentGradientColorsLength: number) => {
  if (recentGradientColorsLength > MAX_COLOR_SIZE / 2) {
    return 566;
  }
  if (myColorsLength > MAX_COLOR_SIZE / 2) {
    return 553;
  }
  if (recentGradientColorsLength > 0) {
    return 530;
  }
  if (myColorsLength > 0) {
    return 517;
  }
  return 469;
};

export const getUniqueColors = (colors: string[]) => {
  const normalizedColors = colors.map((color) => color.toUpperCase());
  const colorCount = normalizedColors.reduce((acc, color) => {
    acc[color] = (acc[color] || 0) + 1;
    return acc;
  }, {} as Record<string, any>);

  const uniqueColors = [...new Set(normalizedColors)].sort((a, b) => colorCount[b] - colorCount[a]);

  return uniqueColors;
};

export const makeGradientColorFormColor = (color?: string) => {
  if (typeof color === 'string' && color?.startsWith(GRADIENT_KEY_WORD)) return color;
  const firstPointID = ID();
  const mainColor = color && color !== TRANSPARENT_COLOR ? color : DEFAULT_START_GRADIENT_COLOR;
  return toGradientString(generateDefaultGradient(firstPointID, mainColor));
};
