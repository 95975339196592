<script lang="ts" setup>
import type { ScreenType } from '@gem/common';
import { getFactors } from '@gem/common';
import { computed } from 'vue';

type LayoutValue = {
  display?: string;
  cols?: number[];
};

const props = withDefaults(
  defineProps<{
    value?: LayoutValue;
    fallback?: LayoutValue;
    maxCol?: number;
    device?: ScreenType;
  }>(),
  {
    maxCol: 1,
  },
);

const emit = defineEmits<{
  (e: 'change', value: number): void;
}>();

const layouts = computed(() => {
  if (props.device === 'desktop')
    return Array.from({ length: props.maxCol }).map((_, index) => {
      return index + 1;
    });
  return getFactors(props.maxCol);
});

const handleChangeLayout = (cols: number) => {
  if (cols === props.value?.cols?.length) return;
  emit('change', cols);
};
</script>

<template>
  <div class="grid w-full grid-cols-3 gap-8">
    <div
      v-for="item in layouts"
      :key="item"
      class="group/box grid h-36 max-w-full cursor-pointer gap-[2px]"
      :style="{ gridTemplateColumns: `repeat(${item}, 1fr)` }"
      @click="handleChangeLayout(item)">
      <span
        v-for="(_, index) in item"
        :key="index"
        class="rounded-medium"
        :class="{
          'bg-primary-300': item === value?.cols?.length,
          'bg-dark-200 group-hover/box:bg-dark-150': item !== value?.cols?.length,
        }"></span>
    </div>
  </div>
</template>
