<script setup lang="ts">
import { InputNumber, type BorderStyle } from '@gem/control-v2';
import { useBorderWidth } from './hooks/useBorderWidth';
import { computed } from 'vue';
import { GButton } from '@gem/uikit-v2';

type Props = {
  id: string;
  value?: BorderStyle;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'controlChange', value?: string): void;
  (e: 'controlOnChange', value?: string): void;
  (e: 'controlLinked', value?: boolean): void;
}>();

const borderValues = computed(() => props.value);
const {
  positionOptions,
  isLink,
  getPositionWidth,
  changeActiveKey,
  handleOnChangeWidth,
  handleInputBlur,
  handleClickLinkAction,
} = useBorderWidth(borderValues, emit);
</script>

<template>
  <div class="flex w-full gap-8">
    <div class="flex w-full flex-col gap-y-8">
      <template v-for="(item, key) in positionOptions">
        <InputNumber
          v-if="!(isLink && key !== 'top')"
          :id="id"
          :key="key"
          :min="0"
          :prefix-icon="isLink ? undefined : `gp-border-${key}`"
          :prefix-icon-no-bg="true"
          :value="getPositionWidth(key)"
          align="center"
          @control-change="handleInputBlur"
          @control-on-change="handleOnChangeWidth"
          @focus="changeActiveKey(key)" />
      </template>
    </div>
    <div class="flex-1">
      <GButton
        type="ghost"
        :active="!isLink"
        size="medium"
        only-icon="gp-border-separated"
        @click="handleClickLinkAction" />
    </div>
  </div>
</template>
