<script lang="ts" setup>
import { GButton } from '@gem/uikit-v2';
const props = defineProps<{
  value?: string;
  selected?: boolean;
}>();

const emit = defineEmits<{
  (e: 'change', value?: string): void;
}>();

const onClick = () => {
  emit('change', props.value);
};
</script>

<template>
  <div class="flex h-full w-full cursor-pointer items-center justify-center" @click="onClick">
    <GButton type="ghost" size="small" :is-square="true" :disable="selected">
      <div
        class="aspect-square rounded-full"
        :class="{
          'border-primary-300 h-12 w-12 border-[4px] bg-transparent': selected,
          'h-4 w-4 bg-white': !selected,
        }"></div>
    </GButton>
  </div>
</template>
