<script lang="ts" setup>
import type { GradientColor, TAB_TYPE } from './types';
import type { ColorPickerModalProps } from '@gem/common';
import { computed, ref, provide, watch, inject, onMounted } from 'vue';
import { onClickOutside, useElementSize, useWindowSize } from '@vueuse/core';
import ColorPicker from './components/ColorPicker.vue';
import ColorList from './components/ColorList.vue';
import GradientPicker from './components/GradientPicker/GradientPicker.vue';
import InputColor from './components/InputColor.vue';
import ColorModalHeader from './components/ColorModalHeader.vue';
import ColorPickerRoot from './components/ColorPickerRoot.vue';
import useMyColor from './composables/useMyColor';
import useGradientColor, { GRADIENT_PROVIDE_KEY } from './composables/useGradientColor';
import useSuggestedColor from './composables/useSuggestedColor';
import useModalControl from './composables/useModalControl';
import ID from '../../utils/id';
import { THEME_DARK } from './const';
import { isColor } from '../../helpers/regex';
import {
  toGradientObject,
  toGradientString,
  generateDefaultGradient,
  TRANSPARENT_COLOR,
  GRADIENT_KEY_WORD,
  DEFAULT_START_GRADIENT_COLOR,
  TABS,
  GRADIENT_TAB,
  SOLID_TAB,
} from './helpers';
import { useKeyboardShortcut } from '@gem/uikit/src/composables/useKeyboardShortcut';

const props = withDefaults(defineProps<ColorPickerModalProps>(), {
  theme: THEME_DARK,
});

const emits = defineEmits<{
  (e: 'controlChange', controlId: string, value?: any): void;
  (e: 'saveColor', newColorList: {}): void;
  (e: 'changeOpenColorModal', value: boolean): void;
}>();

// ==================== Color Control ==================== //
const previousColor = ref<Record<TAB_TYPE, string | undefined>>({
  [GRADIENT_TAB]: undefined,
  [SOLID_TAB]: undefined,
});
const previousPointPosition = ref<number>();
const onChangeColor = (color: string) => {
  const newColor = color ? color : TRANSPARENT_COLOR;
  emits('controlChange', props.id, newColor);
};
const onSaveColor = (newColorList: {}) => emits('saveColor', newColorList);

// ==================== Share display Control ==================== //
const showTransparentColor = computed(() => !props.value || props.value === TRANSPARENT_COLOR);

// ==================== Global Style Control ==================== //
const currentGlobalStyleColor = computed(() => props.currentGlobalStyleColor);

// ==================== Tab Control ==================== //
const tabs = TABS;
const activeTab = ref<TAB_TYPE>(props.value?.includes(GRADIENT_KEY_WORD) ? GRADIENT_TAB : SOLID_TAB);
const recoverSelectedPoint = (gradientColorObject: GradientColor | undefined) => {
  if (!gradientColorObject) return;
  if (previousPointPosition.value !== undefined) {
    selectedPointID.value = [...gradientColorObject!.points.keys()][0];
    gradientColorObject.points.forEach((point, pointId) => {
      if (point.position === previousPointPosition.value) {
        selectedPointID.value = pointId;
      }
    });
  }
};
const onChangeTab = (tab: TAB_TYPE) => {
  if (selectedPoint.value) {
    previousPointPosition.value = selectedPoint.value.position;
  }

  previousColor.value[activeTab.value] =
    activeTab.value === SOLID_TAB ? props.value : toGradientString(gradientColor.value!);
  activeTab.value = tab;
  const firstPointID = ID();
  selectedPointID.value = firstPointID;
  if (activeTab.value === SOLID_TAB) {
    return emits(
      'controlChange',
      props.id,
      previousColor.value[SOLID_TAB] || [...gradientColor.value!.points.values()][0].color.getColor(),
    );
  }

  if (previousColor.value[GRADIENT_TAB]) {
    gradientColor.value = toGradientObject(previousColor.value[GRADIENT_TAB]);
    recoverSelectedPoint(gradientColor.value);
  } else {
    const mainColor =
      props.value && props.value !== TRANSPARENT_COLOR
        ? options.value.color || props.value
        : DEFAULT_START_GRADIENT_COLOR;
    gradientColor.value = generateDefaultGradient(firstPointID, mainColor);
  }

  emits('controlChange', props.id, toGradientString(gradientColor.value!));
};

// ==================== Solid Color Picker Control ==================== //
const openColorPicker = ref(false);
const shouldReRenderDueToInputChange = ref(false);
const value = computed(() => props.value);
const globalColors = computed(() => props.globalColors);
const composeColors = computed(() => {
  if (!props.value || props.value === 'transparent') return '';
  if (isColor(props.value)) return props.value;
  return globalColors.value.find((col) => col.colorType === props.value)?.color as string;
});
const options = useSuggestedColor(globalColors, value);
const { myColors, SUGGESTED_COLOR_FOR_YOU } = useMyColor(currentGlobalStyleColor, globalColors);
const onToggleColorPicker = () => (openColorPicker.value = !openColorPicker.value);
const onChangeSolidColor = (color: string) => {
  onChangeColor(color);
  shouldReRenderDueToInputChange.value = !shouldReRenderDueToInputChange.value;
};

// ==================== Gradient Picker Control ==================== //
const gradientColor = ref<GradientColor | undefined>(toGradientObject(props.value));
const recentGradientColors = computed(() =>
  props.currentGlobalStyleColor && props.currentGlobalStyleColor['recent-gradient-colors']
    ? props.currentGlobalStyleColor['recent-gradient-colors']
    : [],
);
const {
  selectedPointID,
  selectedPoint,
  addPoint,
  updateAngle,
  updateColor,
  updatePosition,
  removePoint,
  selectPoint,
  setSelectedPointID,
  pickRecentColor,
} = useGradientColor(gradientColor, onChangeColor);
provide(GRADIENT_PROVIDE_KEY, {
  selectedPointID,
  addPoint,
  updateAngle,
  updateColor,
  updatePosition,
  removePoint,
  selectPoint,
});
const onInputChange = (value: string) => {
  updateColor(value);
  shouldReRenderDueToInputChange.value = !shouldReRenderDueToInputChange.value;
};

// ==================== Modal Control ==================== //
const mode = computed(() => props.mode);
const currentValue = computed(() => props.value);
const { y, x, modalRoot, modalContent, openColorModal, modalStyle, strategy, onToggleColorModal } = useModalControl(
  mode,
  openColorPicker,
  activeTab,
  myColors,
  recentGradientColors,
  currentValue,
  currentGlobalStyleColor,
  composeColors,
  onSaveColor,
);

const { height: windowHeight } = useWindowSize();
const { height: modalContentHeight, width: modalContentWidth } = useElementSize(modalContent);

watch(openColorModal, (newValue) => {
  emits('changeOpenColorModal', newValue);
  if (newValue && activeTab.value === GRADIENT_TAB && !selectedPointID.value && gradientColor.value) {
    setSelectedPointID([...gradientColor.value.points.keys()][0]);
  }
});

const topStyle = computed(() => {
  if (y.value + modalContentHeight.value >= windowHeight.value) {
    return windowHeight.value - modalContentHeight.value - 24;
  }
  return y.value - 36;
});

const leftStyle = computed(() => x.value + modalContentWidth.value / 2 + 36);

onClickOutside(modalContent, (e: PointerEvent) => {
  if (
    e.target &&
    (e.target as HTMLElement).classList &&
    !(e.target as HTMLElement).classList.contains('picked-color')
  ) {
    onToggleColorModal();
  }
});

useKeyboardShortcut({
  callback(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      onToggleColorModal(true);
    }
  },
});

defineExpose({
  onToggleColorModal,
});

const registerMethod = inject('registerMethod', undefined);

onMounted(() => {
  if (registerMethod) {
    (registerMethod as (key: string, callback: () => void) => void)?.('toggleColorModal', onToggleColorModal);
  }
});
</script>

<template>
  <div
    ref="modalRoot"
    class="color-root-node hover:border-light-450 group float-right flex h-32 w-32 cursor-pointer items-center justify-center rounded-full hover:border"
    @click.stop="onToggleColorModal()">
    <ColorPickerRoot
      :is-active="openColorModal"
      :value="options.color"
      :show-transparent-color="showTransparentColor" />
  </div>
  <Teleport to="#root-modal">
    <Transition>
      <div
        v-if="openColorModal"
        ref="modalContent"
        :class="`color-modal-content bg-dark-500 rounded-12 control-color-modal absolute z-[999] flex w-[312px] flex-col items-center pb-16`"
        :data-is-open="openColorModal"
        :style="{
          position: strategy,
          top: `${topStyle}px`,
          left: `${leftStyle}px`,
        }">
        <ColorModalHeader
          :mode="mode"
          :theme="theme"
          :active-tab="activeTab"
          :tabs="tabs"
          @on-toggle-color-modal="onToggleColorModal"
          @on-change-tab="onChangeTab">
          <template v-if="$slots.header" #header="{ emits: emitColorModal }">
            <slot name="header" :emits="emitColorModal"></slot>
          </template>
        </ColorModalHeader>
        <div class="w-full px-16">
          <template v-if="activeTab === GRADIENT_TAB">
            <GradientPicker
              :theme="theme"
              :gradient-color="gradientColor"
              class="mt-[8.5px] mb-20"
              @on-remove-point="removePoint" />
            <InputColor
              :theme="theme"
              :value="selectedPoint?.color.getColor()"
              :show-transparent-color="showTransparentColor"
              :open-color-picker="true"
              @change-color="onInputChange" />
            <ColorPicker
              :key="`${selectedPointID}_${shouldReRenderDueToInputChange.toString()}`"
              class="mb-16"
              :value="selectedPoint?.color.getColor()"
              :is-auto-update-value="false"
              @change-color="updateColor" />
            <ColorList
              v-if="recentGradientColors && recentGradientColors.length > 0"
              title="Recent colors"
              :colors="recentGradientColors"
              :color="value"
              @on-pick-color="pickRecentColor" />
          </template>
          <template v-else>
            <InputColor
              :theme="theme"
              :value="composeColors"
              :show-transparent-color="showTransparentColor"
              :open-color-picker="openColorPicker"
              @toggle-color-picker="onToggleColorPicker"
              @change-color="onChangeSolidColor" />
            <ColorPicker
              v-if="openColorPicker"
              :key="shouldReRenderDueToInputChange.toString()"
              :value="value"
              :is-auto-update-value="false"
              @change-color="onChangeColor" />
            <ColorList
              class="my-16"
              title="Recent colors"
              :colors="myColors"
              :color="options.color"
              @on-pick-color="onChangeSolidColor" />
            <ColorList
              title="Suggested for you"
              :colors="SUGGESTED_COLOR_FOR_YOU"
              :color="options.color"
              @on-pick-color="onChangeSolidColor" />
          </template>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>
