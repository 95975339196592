<script lang="ts" setup>
import { GSvg } from '@gem/icons';
import { computed } from 'vue';

type LayoutValue = {
  display?: string;
  cols?: number[];
};

const props = withDefaults(
  defineProps<{
    value?: LayoutValue;
    swapped?: boolean;
    maxCol?: number;
  }>(),
  {
    maxCol: 1,
  },
);

const emit = defineEmits<{
  (e: 'changeLayout', value: number): void;
  (e: 'swap-order', contentSide: 'left' | 'right'): void;
}>();

const swapOrder = (contentSide: 'left' | 'right') => {
  console.log('contentSide', contentSide);
  emit('swap-order', contentSide);
};

const layouts = computed(() => {
  return [
    {
      active: props.value?.cols?.length === 2 && !props.swapped,
      icon: 'gp-hero-banner-layout-left',
      name: 'Content left',
      col: 2,
      onClick: () => {
        handleChangeLayout(2);
        swapOrder('left');
      },
    },
    {
      active: props.value?.cols?.length === 1,
      col: 1,
      icon: 'gp-hero-banner-layout-center',
      name: 'Content center',
      onClick: () => {
        handleChangeLayout(1);
      },
    },
    {
      active: props.value?.cols?.length === 2 && props.swapped,
      icon: 'gp-hero-banner-layout-right',
      col: 2,
      name: 'Content right',
      onClick: () => {
        handleChangeLayout(2);
        swapOrder('right');
      },
    },
  ];
});

const handleChangeLayout = (cols: number) => {
  emit('changeLayout', cols);
};
</script>

<template>
  <slot></slot>
  <div class="grid w-full grid-cols-3 gap-8">
    <div
      v-for="item in layouts"
      :key="item.col"
      class="group/layout grid max-w-full cursor-pointer gap-[2px] rounded-xl border border-transparent"
      :class="{
        'border-primary-300': item.active,
      }"
      @click="item?.onClick()">
      <div class="bg-dark-400 hover:bg-dark-250 flex items-center justify-center overflow-hidden rounded-xl">
        <GSvg :name="item.icon" />
      </div>
    </div>
  </div>
</template>
