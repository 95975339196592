export function useParseLinkToType(input: string) {
  switch (true) {
    case input === '/':
      return 'open-page';

    case input.startsWith('mailto:'):
      return 'send-email';

    case input.startsWith('tel:'):
      return 'call-phone';

    case input.startsWith('#') && !input.startsWith('#el-'):
      return 'scroll-to';

    case input.startsWith('#el-'):
      return 'open-popup';

    default:
      try {
        new URL(input);
        return 'open-page';
      } catch (e) {
        return 'open-page';
      }
  }
}
