<script setup lang="ts">
import type { ControlChangeValueType, SettingUIControl, SettingUIGroup } from '@gem/control-v2';
import { computed, ref } from 'vue';
import SettingTitle from './SettingTitle.vue';
import ToggleSettings from './ToggleSettings.vue';
import MoreSettings from './MoreSettings.vue';
import ControlSettings from './ControlSettings.vue';
import { useDisplayResponsive } from './hooks/useDisplayResponsive';
import useSettingSideBarStore from '../hooks/useSettingSideBarStore';
import type { ScreenType } from '@gem/common';
import { cn } from '@gem/common';
import TabSettings from './TabSettings.vue';

const props = defineProps<{
  data: SettingUIGroup;
  noBorder?: boolean;
}>();

const emit = defineEmits<{
  (event: 'controlChange', value: ControlChangeValueType): void;
  (event: 'controlOnChange', value: ControlChangeValueType): void;
}>();
const settingSideBarStore = useSettingSideBarStore();
const currentDevice = computed(() => settingSideBarStore.currentDevice);
const moreSettings = computed(() => props.data.moreSettings);
const states = computed(() => props.data.states);

const moreSettingsControlDisplay = computed(
  () => props.data.moreSettings?.controls?.filter((item) => !item.hide) || [],
);
const toggleSettings = computed(() => props.data.toggleSettings);
const toggleSettingTabs = computed(() => {
  return toggleSettings?.value?.map((item) => {
    return {
      value: item.id,
      title: item.label?.en || '',
    };
  });
});

const controlsFromToggleSetting = computed(() => {
  const controls: SettingUIControl[] = [];
  toggleSettings?.value?.forEach((item) => {
    if (item.controls) {
      item.controls.forEach((control) => {
        controls.push({
          ...control,
          toggleGroupId: item.id,
        });
      });
    } else {
      controls.push({
        ...item,
        toggleGroupId: item.id,
      });
    }
  });
  return controls.filter((item) => isDisplaySetting(item));
});

const toggleSettingsTabSelected = ref<string[]>([]);
const toggleDisplaySettings = (toggleSettingIDSelected: string[]) => {
  toggleSettingsTabSelected.value = toggleSettingIDSelected;
};

const displayControls = computed(() => {
  const controls = [...(props.data.controls || []), ...controlsFromToggleSetting.value];
  return controls.filter((item) => isDisplaySetting(item));
});

const isDisplaySetting = (control: SettingUIControl) => {
  if (control.hide) return false;
  if (props.data.disableToggle || !toggleSettingTabs.value?.length) return true;
  if (control.toggleGroupId) return toggleSettingsTabSelected.value.includes(control.toggleGroupId);
  return true;
};

const displayResponsive = computed(() => {
  const hasLabel = props.data.label;
  return hasLabel ? useDisplayResponsive([...moreSettingsControlDisplay.value, ...displayControls.value]) : 'control';
});

const handleControlChange = (data: ControlChangeValueType) => {
  emit('controlChange', data);
};

const handelControlOnChange = (data: ControlChangeValueType) => {
  emit('controlOnChange', data);
};

const handleChangeActiveDevice = (device: ScreenType) => {
  settingSideBarStore.setCurrentDevice(device);
};
</script>

<template>
  <div
    class="flex flex-col gap-12 pt-12 pb-20"
    :class="cn([noBorder ? '' : 'border-dark-300 border-b', data.options?.displayNone ? 'hidden' : ''])">
    <div v-if="data.label" class="flex items-center justify-between">
      <SettingTitle
        class="w-full"
        data-test="group-title"
        :show-devices="displayResponsive === 'group'"
        :current-device="currentDevice"
        :label="data.label"
        :help="data.help"
        @change-screen="handleChangeActiveDevice" />
      <ToggleSettings data-test="group-toggle-settings" :data="toggleSettings" @on-select="toggleDisplaySettings" />
    </div>
    <div
      v-if="displayControls.length || moreSettingsControlDisplay?.length || states?.length"
      class="flex flex-col gap-16">
      <ControlSettings
        v-if="displayControls.length"
        :controls="displayControls"
        :is-top-level="true"
        :hidden-device="displayResponsive === 'group'"
        @control-change="handleControlChange"
        @control-on-change="handelControlOnChange" />
      <TabSettings :data="states" @control-change="handleControlChange" @control-on-change="handelControlOnChange" />
      <MoreSettings v-if="moreSettingsControlDisplay?.length" data-test="group-more-settings" :data="moreSettings">
        <ControlSettings
          :controls="moreSettingsControlDisplay"
          :hidden-device="displayResponsive === 'group'"
          :is-top-level="true"
          @control-change="handleControlChange"
          @control-on-change="handelControlOnChange" />
      </MoreSettings>
    </div>
  </div>
</template>
