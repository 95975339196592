import type { ColorProps, CustomColorType, GlobalStyleResponsiveConfig } from '@gem/common';
import { computed } from 'vue';

export const useGlobalStyleData = (globalStyle?: GlobalStyleResponsiveConfig) => {
  const globalStyleColorList = computed(() => {
    const colors: ColorProps[] = [];
    Object.assign(
      colors,
      Object.entries(globalStyle?.color || {}).map(([key, value]) => {
        if ((key as keyof CustomColorType) && typeof value !== 'object') {
          return {
            colorType: key as CustomColorType,
            color: value,
          };
        }
        return {};
      }),
    );
    return colors.filter((col) => !!col.colorType).concat({ colorType: 'transparent', color: 'transparent' });
  });
  return {
    globalStyleColorList,
  };
};
