<script setup lang="ts">
import type { BorderSettingProps } from './types';
import { ComboSettingLayout, ControlLayout, Segment } from '@gem/control-v2';
import { useBorderSettings } from './hooks/useBorderSettings';
import BorderThickness from './BorderThickness.vue';
import ColorPicker from '../color-picker/ColorPicker.vue';
import type { ColorSuggestions } from '@gem/common';

const props = defineProps<BorderSettingProps>();

const emit = defineEmits<{
  (e: 'controlChange', controlId?: string, value?: BorderSettingProps['value']): void;
  (e: 'controlOnChange', controlId?: string, value?: BorderSettingProps['value']): void;
  (e: 'updateColorSuggestion', value?: ColorSuggestions): void;
}>();

const {
  borderValues,
  typeOptions,
  borderTypeOptionSelected,
  isLinked,
  handleUpdateSetting,
  handleClearBorder,
  handleSetDefaultValue,
  handleUpdateSuggestionColor,
  handleUpdateLinked,
} = useBorderSettings(props, emit);
</script>
<template>
  <div>
    <ComboSettingLayout
      :id="id"
      placeholder="Add..."
      :label="{ en: 'Border' }"
      :show-label="false"
      :is-full-width="true"
      :content-display="borderTypeOptionSelected?.label"
      :combo-icon="borderTypeOptionSelected?.iconName"
      :level="level || 0"
      :combo-i-d="comboID || ''"
      :combo-parent-i-d="comboParentID || ''"
      :combo-root-parent-i-d="comboRootParentID || ''"
      @click="handleSetDefaultValue"
      @clear="handleClearBorder">
      <ControlLayout :label="{ en: 'Color' }" layout="horizontal">
        <template #control>
          <ColorPicker
            v-bind="props"
            :id="'color'"
            :value="borderValues?.color"
            :level="(level || 0) + 1"
            :combo-i-d="`${comboID}-color`"
            :combo-parent-i-d="comboID || ''"
            :combo-root-parent-i-d="comboRootParentID || ''"
            @save-my-colors="handleUpdateSuggestionColor"
            @control-change="(_, value) => handleUpdateSetting('color', 'controlChange', value)"
            @control-on-change="(_, value) => handleUpdateSetting('color', 'controlOnChange', value)" />
        </template>
      </ControlLayout>
      <ControlLayout :label="{ en: 'Thickness' }" layout="horizontal" :align="isLinked ? 'center' : 'top'">
        <template #control>
          <BorderThickness
            :id="'width'"
            :value="borderValues"
            :options="typeOptions"
            @control-linked="handleUpdateLinked"
            @control-change="(value) => handleUpdateSetting('thickness', 'controlChange', value)"
            @control-on-change="(value) => handleUpdateSetting('thickness', 'controlOnChange', value)" />
        </template>
      </ControlLayout>
      <ControlLayout :label="{ en: 'Stroke' }" layout="horizontal">
        <template #control>
          <Segment
            :id="'style'"
            background-class="bg-dark-400"
            :value="borderTypeOptionSelected?.value"
            :options="typeOptions"
            @control-change="(_, value) => handleUpdateSetting('type', 'controlChange', value as string)" />
        </template>
      </ControlLayout>
    </ComboSettingLayout>
  </div>
</template>
