<script lang="ts" setup>
import GridItem from './GridItem.vue';
import { useGridArrange } from './hooks/useGridArrange';

const props = withDefaults(
  defineProps<{
    cols?: number[];
    disabled?: boolean;
  }>(),
  {
    cols: () => [],
  },
);

const emit = defineEmits<{
  (e: 'control-change', value: number[]): void;
  (e: 'control-on-change', value: number[]): void;
}>();

const { gridTemplateColumns, mainArea, columns, changeCols, onChangeCols } = useGridArrange(props, emit);
</script>

<template>
  <div
    ref="mainArea"
    :draggable="false"
    class="bg-dark-400 grid h-36 rounded-xl p-4 text-white"
    :style="{
      gridTemplateColumns: gridTemplateColumns,
    }">
    <GridItem
      v-for="(item, index) in columns"
      :key="index"
      :disabled="disabled"
      :cols="columns"
      :area="mainArea"
      :value="item"
      :index="index"
      :total-col="columns.length"
      @update-position="changeCols"
      @on-update-position="onChangeCols">
    </GridItem>
  </div>
</template>
