import type { OptionItem } from '@gem/control-v2';

export const transformOptions: OptionItem[] = [
  {
    value: 'none',
    iconName: 'gp-line',
  },
  {
    label: 'AA',
    value: 'uppercase',
  },
  {
    label: 'Aa',
    value: 'capitalize',
  },
  {
    label: 'aa',
    value: 'lowercase',
  },
];

export const lineHeightOptions: OptionItem[] = [
  {
    label: '100%',
    value: '100%',
  },
  {
    label: '120%',
    value: '120%',
  },
  {
    label: '130%',
    value: '130%',
  },
  {
    label: '150%',
    value: '150%',
  },
  {
    label: '180%',
    value: '180%',
  },
];

export const textAlignOptions: OptionItem[] = [
  {
    label: 'Left',
    value: 'left',
    tooltip: 'Align left',
    iconName: 'polaris-text-align-left',
  },
  {
    label: 'Center',
    value: 'center',
    tooltip: 'Align center',
    iconName: 'polaris-text-align-center',
  },
  {
    label: 'Right',
    value: 'right',
    tooltip: 'Align right',
    iconName: 'polaris-text-align-right',
  },
  {
    label: 'Justify',
    value: 'justify',
    tooltip: 'Align justify',
    iconName: 'gp-text-align-justify',
  },
];
