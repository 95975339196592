<script setup lang="ts">
import type { CornerRadius } from '@gem/control-v2';
import { computed, ref, watch } from 'vue';
import { GButton, GInput, GPopover } from '@gem/uikit-v2';
import { GIcon } from '@gem/icons';
import type { OptionItem } from '@gem/common';

const props = defineProps<{
  value?: any;
  options?: OptionItem[];
  isFocus?: boolean;
}>();

const emit = defineEmits<{
  (e: 'controlChange', value: CornerRadius): void;
  (e: 'controlOnChange', value: CornerRadius): void;
  (e: 'change-link', value: boolean): void;
}>();

const borderRadiusInputs = computed(() => (isLinked.value ? ['btlr'] : ['btlr', 'btrr', 'bblr', 'bbrr']));

const isShowDropDown = ref(false);
const dropdownRef = ref<HTMLElement[]>([]);
const itemRefs = ref<HTMLElement[]>([]);
const val = computed(() => props.value);

const isLinked = computed(() => {
  return val.value.radiusType !== 'custom';
});

const getValueByKey = (key: string) => {
  const valueByKey = val.value?.[key] as string;
  if (valueByKey) {
    return valueByKey.includes('%') ? valueByKey : convertValueToNumber(valueByKey);
  }
  return valueByKey;
};
const convertValueToNumber = (value: string) => {
  const num = parseInt(value);
  return isNaN(num) ? 0 : num;
};

const isDisableInput = (key: string) => {
  return isLinked.value && key !== 'btlr';
};

const getStringValueByKey = (key: string) => {
  return (val.value?.[key as keyof CornerRadius] as string) || '0px';
};

const handleEmit = (type: 'change' | 'onChange', key: string, value?: string, radiusType?: string) => {
  let valueChange = !value ? '0' : value;
  if (value && !value?.includes('%')) {
    valueChange = convertValueToNumber(value) + 'px';
  }
  const dataChange = isLinked.value
    ? {
        ...val.value,
        bblr: valueChange,
        bbrr: valueChange,
        btlr: valueChange,
        btrr: valueChange,
        ...(radiusType && { radiusType }),
      }
    : {
        ...val.value,
        [key]: valueChange,
      };
  if (type === 'onChange') {
    emit('controlOnChange', dataChange);
  } else emit('controlChange', dataChange);
};
const onChangeInput = (key: string, value?: string) => {
  handleEmit('onChange', key, value);
};

const changeInput = (key: string, value?: string) => {
  handleEmit('change', key, value ?? '0');
};

const changeOption = (key: string, keyword?: string) => {
  const opt = props.options?.find((opt: any) => opt.keyword === keyword);
  handleEmit('change', key, opt?.value ?? '0', keyword === 'circle' ? 'circle' : 'rounded');
};

const blurInput = (key: string, value?: string, popoverOpen?: boolean) => {
  if (value === '0' && isLinked.value && !popoverOpen) {
    handleEmit('change', key, value);
  }
  if (value === '') {
    handleEmit('change', key, '0');
  }
};

const handleLink = () => {
  emit('change-link', !isLinked.value);
};

const onMouseLeave = (index: number) => {
  !(dropdownRef.value[index] as any)?.open && (isShowDropDown.value = false);
};

watch(
  () => props.isFocus,
  (newV) => {
    newV && itemRefs.value && itemRefs.value[0]?.focus();
  },
);
</script>
<template>
  <div class="item flex items-start gap-8">
    <div class="w-full items-center gap-8" :class="{ 'grid grid-cols-2': !isLinked }">
      <div
        v-for="(item, index) in borderRadiusInputs"
        :key="index"
        class="relative"
        @focus="() => (isShowDropDown = true)"
        @mouseover="() => (isShowDropDown = true)"
        @mouseleave="() => onMouseLeave(index)">
        <GInput
          :ref="(el: any) => (itemRefs[index] = el)"
          :value="getValueByKey(item)"
          type="number"
          size="medium"
          align="center"
          :disable="isDisableInput(item)"
          @click-out-side="(value: string) => changeInput(item, value)"
          @focus="() => (isShowDropDown = true)"
          @on-change="(value: string) => onChangeInput(item, value)"
          @change="(value: string) => changeInput(item, value)"
          @blur="(value: string) => blurInput(item, value)">
        </GInput>
        <div v-if="isLinked && isShowDropDown" class="absolute top-8 right-0 cursor-pointer">
          <GPopover
            :ref="(el: any) => (dropdownRef[index] = el)"
            :has-arrow="false"
            :closeable="true"
            :overlay="false"
            cls="bg-dark-400"
            overlay-container="#root-modal"
            wrap-content-class="!w-full"
            :placement-offset="4"
            :z-index="9999"
            placement="bottom-end"
            @close="() => (isShowDropDown = false)">
            <template #default
              ><div class="px-4"><GIcon :size="20" name="polaris-caret-down" /></div
            ></template>
            <template v-if="options?.length" #content="{ close }">
              <div
                v-for="(opt, i) in options"
                :key="i"
                class="text-12 hover:bg-light-100/20 text-light-100 relative flex h-36 w-full min-w-[196px] cursor-pointer items-center truncate whitespace-nowrap rounded-xl pl-40 pr-8"
                @click.prevent="
                  () => {
                    changeOption(item, opt.keyword);
                    close();
                    isShowDropDown = false;
                  }
                ">
                <span
                  v-if="getStringValueByKey(item).toLocaleLowerCase() === opt.value?.toLocaleLowerCase()"
                  class="text-light-450 absolute inset-y-0 left-8 flex items-center">
                  <GBaseIcon name="status-check" width="20" height="20" />
                </span>
                <p class="flex w-full items-center justify-between truncate">
                  <span class="truncate">{{ opt.title }}</span>
                  <span v-if="opt.showValue" class="text-text-dark-300">{{ opt.valueDisplay || opt.value }}</span>
                </p>
              </div>
            </template>
          </GPopover>
        </div>
      </div>
    </div>
    <GButton
      type="ghost"
      size="semi-medium"
      only-icon="gp-corner-single"
      :icon-size="20"
      icon-view-box="0 0 20 20"
      :active="!isLinked"
      @click="handleLink" />
  </div>
</template>
