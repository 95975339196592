<script setup lang="ts">
import type { ImageSettingProps } from './types';
import { ComboSettingLayout, ControlLayout, Toggle } from '@gem/control-v2';
import { Input, Textarea } from '@gem/control';
import { computed } from 'vue';

const props = defineProps<ImageSettingProps>();
const val = computed(() => props.value);

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: any): void;
  (e: 'controlOnChange', controlId: string, value?: any): void;
}>();

const handleChangeValue = (type: string, value?: any) => {
  emit('controlChange', props.id, { ...val.value, [type]: value });
};
</script>
<template>
  <div>
    <ComboSettingLayout
      :id="id"
      placeholder="Add..."
      :is-parent="true"
      :label="label ?? { en: 'Image' }"
      :show-label="false"
      :is-full-width="true"
      :combo-image="value?.src"
      :content-display="value?.src"
      :level="level || 0"
      :combo-i-d="comboID || ''"
      :combo-parent-i-d="comboParentID || ''"
      :combo-root-parent-i-d="comboRootParentID || ''"
      :is-hide-clear="true">
      <div class="flex flex-col gap-12">
        <div class="border-dark-300 flex flex-col gap-12 border-b pb-4">
          <ControlLayout layout="vertical" :label="{ en: 'Source' }" label-variant="primary">
            <template #control>
              <slot></slot>
            </template>
          </ControlLayout>
        </div>
        <div class="border-dark-300 flex flex-col gap-12 border-b pb-20">
          <ControlLayout layout="vertical" :label="{ en: 'SEO' }" label-variant="primary">
            <template #control>
              <div class="flex flex-col gap-16">
                <ControlLayout :label="{ en: 'Alt text' }" align="top">
                  <template #control>
                    <Textarea
                      :value="val?.altText"
                      :auto-height="true"
                      :default-rows="3"
                      placeholder="E.g: Classic white crewneck t-shirt with minimalist logo"
                      @control-change="(_: any, value: string) => handleChangeValue('altText', value)" />
                  </template>
                </ControlLayout>
                <ControlLayout :label="{ en: 'Image title' }">
                  <template #control>
                    <Input
                      id="imageTitle"
                      :value="val?.imageTitle"
                      placeholder="E.g: White t-shirt"
                      @control-change="(_: any, value: string) => handleChangeValue('imageTitle', value)" />
                  </template>
                </ControlLayout>
              </div>
            </template>
          </ControlLayout>
        </div>
        <ControlLayout layout="vertical" :label="{ en: 'Optimize LCP' }" label-variant="primary">
          <template #control>
            <slot name="optimize-lcp"></slot>
            <ControlLayout :label="{ en: 'Preload' }">
              <template #control>
                <Toggle
                  :value="val?.preload || false"
                  @control-change="(_: any, value?: boolean) => handleChangeValue('preload', value)"
              /></template>
            </ControlLayout>
          </template>
        </ControlLayout>
      </div>
    </ComboSettingLayout>
  </div>
</template>
