<template>
  <Teleport to="body">
    <g-modal
      :is-open="galleryModelVisible"
      :container-class="[
        'gallery-model-container left-[337px] top-[50px] w-[600px] max-h-[calc(100vh_-_72px)] max-w-[90%]',
        offsetLeft ? `transform translate-x-${offsetLeft}` : '',
      ]"
      modal-class="rounded-[16px] max-h-[calc(100vh_-_72px)]"
      body-class="pt-0 bg-white overflow-hidden h-full"
      hide-actions
      :show-line-top="false"
      show-btn-close
      @close="close">
      <template #title>
        <div class="flex items-center justify-between p-16 py-12 pr-8">
          <div class="text-16 text-text-light-500 font-semibold tracking-[-0.32px]">Choose from gallery</div>
        </div>
      </template>
      <template #default>
        <TabGroup :selected-index="selectedIndex">
          <TabList
            class="first-letter:r border-light-400 mx-16 mb-16 flex h-full items-center justify-center gap-2 space-x-4 rounded-xl border bg-white p-4 text-center">
            <Tab v-for="tab in Object.keys(tabs)" :key="tab" v-slot="{ selected }" as="template">
              <button
                data-test="editor-control-modal-tab-title"
                :class="[
                  'text-12 flex-1 rounded-[6px] py-4 font-medium ',
                  'text-text-light-300 hover:bg-light-300 leading-5 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none',
                  selected && 'bg-underlay-light-blue !text-text-light-500 hover:bg-underlay-light-blue',
                ]"
                @click="handleSelectedTab(tab)">
                {{ Object(tabs)[tab] }}
              </button>
            </Tab>
          </TabList>
          <TabPanels class="bg-light-400 h-full flex-grow overflow-hidden">
            <TabPanel
              :class="[' ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none']"
              data-test="editor-control-modal-display-gallery">
              <div
                v-show="selectedTab === 'gallery'"
                class="custom-image-upload-modal-display-body low:h-[70vh] h-[614px]">
                <perfect-scrollbar ref="imageListInfinityScroll" class="p-16">
                  <div
                    class="custom-image-upload-modal-display-body-list"
                    :class="{
                      'h-[136px]': !imageList?.length,
                    }">
                    <div
                      data-test="editor-control-modal-button-upload"
                      class="custom-image_upload_item group/plus !border-light-500 flex items-center justify-around !rounded-[12px] bg-white">
                      <input
                        id="input"
                        class="custom-item-upload-input h-full w-full"
                        type="file"
                        accept="image/*"
                        @change="handleChangeFile" />
                      <div class="custom-add_more_text text-text-light-100 group-hover/plus:text-text-light-500">
                        <svg width="14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                          <path
                            d="M7.5 0.5C7.5 0.223858 7.27614 0 7 0C6.72386 0 6.5 0.223858 6.5 0.5V6.5H0.5C0.223858 6.5 0 6.72386 0 7C0 7.27614 0.223858 7.5 0.5 7.5H6.5V13.5C6.5 13.7761 6.72386 14 7 14C7.27614 14 7.5 13.7761 7.5 13.5V7.5H13.5C13.7761 7.5 14 7.27614 14 7C14 6.72386 13.7761 6.5 13.5 6.5H7.5V0.5Z"
                            fill="currentColor" />
                        </svg>
                      </div>
                    </div>
                    <div
                      v-for="item in imageList"
                      :key="item?.node?.id"
                      data-test="editor-control-modal-gallery-item"
                      class="custom-image_upload_item bg-white"
                      @click.stop="
                        handleClickImageItem({
                          filePath: item?.node?.filePath,
                          width: item?.node?.width,
                          height: item?.node?.height,
                          fileName: item?.node?.fileName,
                          backupFileKey: item?.node?.backupFileKey,
                          mimeType: item?.node?.mimeType,
                          fileKey: item?.node?.fileKey,
                        })
                      ">
                      <div class="custom-image_wrap_image_item overflow-hidden">
                        <img
                          class="custom-image_item"
                          :src="getImageUrlPreview(item?.node)"
                          alt="image"
                          @load="loadingImageList(item?.node?.id)" />
                      </div>
                      <div
                        v-if="loading.indexOf(item?.node?.id) === -1"
                        class="custom-loading absolute h-full w-full"></div>
                      <div class="custom-background-item-blur">
                        <div v-if="!item.isShow">
                          <div class="custom-delete-label-isShow px-12 pt-12">
                            <div class="filename-wrap">
                              <p>{{ handleFileName(item.node) }}</p>
                            </div>
                            <p>{{ (item.node.size / 1024).toFixed(2) }} kb</p>
                          </div>
                          <div
                            class="custom-delete_item"
                            data-test="editor-control-modal-gallery-item-delete"
                            @click.stop="handleClickDelete(item?.node?.id)">
                            <g-base-icon
                              class="hover:text-text-dark-500 active:text-text-dark-500 text-red-200"
                              name="delete-version"
                              width="16"
                              height="16" />
                          </div>
                        </div>

                        <!-- <div v-else class="custom-delete_confirm" @click.stop="handleDeleteItemCancel(item?.node?.id)"> -->
                        <div
                          v-else
                          class="custom-delete_confirm flex-col"
                          @click.stop="handleDeleteItemCancel(item?.node?.id)">
                          <div class="custom-delete-label">Delete Image?</div>
                          <p class="text-12 text-text-dark-300 mt-4 ml-12 leading-5">
                            {{ (item.node.size / 1024).toFixed(2) }} kb
                          </p>
                          <div class="absolute right-12 bottom-12 flex gap-10">
                            <GButtonV2
                              type="ghost"
                              class="hover:!bg-dark-250 w-[56px]"
                              data-test="editor-control-modal-gallery-item-delete-cancel"
                              size="small"
                              @click="handleDeleteItemCancel(item?.node?.id)"
                              >Cancel</GButtonV2
                            >

                            <GButtonV2
                              type="danger"
                              class="w-[54px]"
                              data-test="editor-control-modal-gallery-item-delete-confirm"
                              size="small"
                              @click.stop="handleDeleteItem(item?.node?.id)"
                              >Delete</GButtonV2
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
            </TabPanel>
            <TabPanel
              data-test="editor-control-modal-recently-deleted"
              :class="[
                'bg-light-300 h-full rounded-xl',
                'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none',
              ]">
              <div
                v-show="selectedTab === 'recentlyDeleted'"
                class="custom-image-upload-modal-display-body low:h-[70vh] h-[614px]">
                <perfect-scrollbar ref="imageDeletedListInfinityScroll" class="p-16">
                  <div class="custom-image-upload-modal-display-body-list">
                    <div
                      v-for="item in imageDeletedList"
                      :key="item?.node?.id"
                      data-test="editor-control-modal-deleted-item"
                      class="custom-image_upload_item bg-white">
                      <div class="custom-image_wrap_image_item overflow-hidden">
                        <img
                          class="custom-image_item"
                          :src="getImageUrlPreview(item?.node)"
                          alt="image"
                          @load="loadingImageDeleted(item?.node?.id)" />
                      </div>
                      <div
                        v-if="loading.indexOf(item?.node?.id || '') === -1"
                        class="custom-loading absolute h-full w-full"></div>
                      <div class="custom-background-item-blur">
                        <div v-if="!item.node?.isShow" class="h-full">
                          <div class="custom-delete-label-isShow px-12 pt-12">
                            <div class="filename-wrap">
                              <p>{{ handleFileName(item.node) }}</p>
                            </div>
                            <p>{{ (item.node?.size || 0 / 1000).toFixed(2) }} kb</p>
                          </div>
                          <div class="absolute right-12 bottom-12 flex gap-10">
                            <GButtonV2
                              type="ghost"
                              data-test="editor-control-modal-deleted-item-recover"
                              class="hover:!bg-dark-250 w-[63px]"
                              size="small"
                              @click.stop="handleClickRestoreDeleteItem(item?.node?.id)"
                              >Recover</GButtonV2
                            >

                            <GButtonV2
                              type="danger"
                              data-test="editor-control-modal-deleted-item-delete"
                              class="w-[54px]"
                              size="small"
                              @click.stop="item?.node && handleForceDeleteItem(item?.node?.id)"
                              >Delete</GButtonV2
                            >
                          </div>
                        </div>
                        <div v-else class="custom-delete_confirm">
                          <div class="custom-delete-label-isShow">
                            <p>Permanently Delete Image?</p>
                          </div>
                          <div class="custom-delete-action">
                            <div
                              class="text-small rounded-medium bg-dark-100 ml-3 mr-8 mb-8 flex h-24 w-[56px] cursor-pointer items-center justify-center font-medium text-white decoration-white"
                              @click.stop="handleForceDeleteItemCancel(item?.node?.id)">
                              Cancel
                            </div>
                            <div
                              class="text-small rounded-medium mr-12 mb-12 bg-red-300 px-8 py-4 text-white"
                              @click.stop="handleForceDeleteItem(item?.node?.id)">
                              Delete
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </template>
    </g-modal>
  </Teleport>
</template>
<script lang="ts" setup>
import { GModal } from '@gem/uikit';
import { ref, computed } from 'vue';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
import { useInfiniteScroll } from '@vueuse/core';
import { getUploadCareImageUrlByMimeType, getImageUrlPreview } from '../../helpers/image';
import type { FileSelectFragment } from '@gem/control/src/type/graphql';

const props = defineProps<{
  galleryModelVisible?: boolean;
  imageSource?: any;
  imageList?: any[];
  imageDeletedList?: FileSelectFragment[];
  viewMore?: boolean;
  viewMoreDeleted?: boolean;
  maximumSize: number;
  allowedFiles: string[];
  offsetLeft?: number;
}>();

const emit = defineEmits<{
  (e: 'closeGalleryVisible', visible: boolean): void;
  (
    e: 'modalChange',
    value: {
      filePath: string;
      width: number;
      height: number;
      fileName: string;
      backupFileKey: string;
      storage: 'THEME' | 'FILE_CONTENT';
    },
  ): void;
  (e: 'deleteImage', id: string): void;
  (e: 'deleteForceImage', id: string): void;
  (e: 'showMore', value: string): void;
  (e: 'handleChangeListImage', value: string): void;
  (e: 'handleLoadImagesDeleted', value: string): void;
  (e: 'restore', id: string): void;
  (e: 'uploadImage', file: File): void;
  (e: 'isLoading', loading: boolean): void;
  (e: 'alert', value: 'size' | 'format' | ''): void;
  (e: 'updateImageProp', value: object): void;
  (e: 'deleteItemCancel', id: string): void;
}>();

const modalVisible = ref(props.galleryModelVisible);
const selectedTab = ref<'gallery' | 'recentlyDeleted'>('gallery');
const loading = ref<string[]>([]);
const imageListInfinityScroll = ref<HTMLElement | null>(null);
const imageDeletedListInfinityScroll = ref<HTMLElement | null>(null);
const MAX_CHARACTERS_ALLOWED = 28;
const tabs = {
  gallery: 'Gallery',
  recentlyDeleted: 'Recent deleted',
};

// image list
useInfiniteScroll(
  imageListInfinityScroll,
  () => {
    // load more
    if (props.viewMore) {
      emit('showMore', 'listImage');
    }
  },
  { distance: 100 },
);

// deleted list
useInfiniteScroll(
  imageDeletedListInfinityScroll,
  () => {
    // load more
    if (props.viewMoreDeleted) {
      emit('showMore', 'deleted');
    }
  },
  { distance: 100 },
);

const selectedIndex = computed(() => {
  const index = Object.keys(tabs).findIndex((key) => key === selectedTab.value);
  return index !== -1 ? index : 0;
});

const handleSelectedTab = (tab: string) => {
  if (selectedTab.value == tab) return;
  if (tab == 'gallery') {
    handleClickListImage();
  } else {
    handleClickDeleted();
  }
};

const handleFileName = (file: any) => {
  let fileName = '';
  const fileExtension = file.fileName.split('.').pop();

  //file name has no extension
  if (!fileExtension) {
    file.fileName.length > MAX_CHARACTERS_ALLOWED
      ? (fileName = `${file.fileName.substring(0, MAX_CHARACTERS_ALLOWED)}...`)
      : (fileName = file.fileName);
  } else {
    file.fileName.length > MAX_CHARACTERS_ALLOWED
      ? (fileName = `${file.fileName.substring(
          0,
          MAX_CHARACTERS_ALLOWED - fileExtension.length - 9,
        )}...${file.fileName.substring(
          file.fileName.length - fileExtension.length - 3,
          file.fileName.length - fileExtension.length - 1,
        )}.${fileExtension}`)
      : (fileName = file.fileName);
  }
  return fileName;
};

const postUploadImage = async (file: File) => {
  emit('uploadImage', file);
};

const handleClickImageItem = (data: any) => {
  emit('modalChange', {
    filePath: getUploadCareImageUrlByMimeType(data),
    width: data.width,
    height: data.height,
    fileName: data.filename,
    backupFileKey: data.backupFileKey,
    storage: data.fileKey?.includes('gid://shopify/') ? 'FILE_CONTENT' : 'THEME',
  });
  close();
};

const close = () => {
  modalVisible.value = false;
  emit('closeGalleryVisible', modalVisible.value);
};

const handleClickDeleted = () => {
  loading.value.length = 0;
  selectedTab.value = 'recentlyDeleted';
  emit('handleLoadImagesDeleted', 'deleted');
};

const handleClickListImage = () => {
  loading.value.length = 0;
  selectedTab.value = 'gallery';
};

const handleChangeFile = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (!target.files?.length) return;
  const file = target.files.item(0);
  if (!file) return;
  if (!props.allowedFiles.includes(file.type)) {
    emit('alert', 'format');
    close();
    return;
  }
  if (file.size > props.maximumSize) {
    emit('alert', 'size');
    close();
    return;
  }
  postUploadImage(file);
  emit('isLoading', true);
  close();
};

// Image List
const handleClickDelete = (id: string) => {
  emit('updateImageProp', { id, type: 'list', isShow: true });
};

// const handleDeleteItemCancel = (id: string) => {
//   emit('updateImageProp', { id, type: 'list', isShow: false });
// };

const handleDeleteItemCancel = (id: string) => {
  emit('deleteItemCancel', id);
};

// Image List Deleted
const handleClickForceDeleteItem = (id?: string) => {
  if (id) emit('updateImageProp', { id, type: 'deleted', isShow: true });
};

const handleForceDeleteItemCancel = (id: string) => {
  emit('updateImageProp', { id, type: 'deleted', isShow: false });
};

const handleClickRestoreDeleteItem = (id?: string) => {
  if (id) emit('restore', id);
};

const handleForceDeleteItem = (_id: string) => {
  emit('deleteForceImage', _id);
};

// Delete Image
const handleDeleteItem = (id: string) => {
  emit('deleteImage', id);
};

const loadingImageList = (id: string) => {
  loading.value.push(id);
};

const loadingImageDeleted = (id?: string) => {
  loading.value.push(id || '');
};
</script>

<style lang="scss" scoped>
.tung {
  width: 39em;
}
.custom-image-upload-modal-display {
  background-color: white;
  border-radius: 3px;

  &-body {
    &::-webkit-scrollbar {
      display: none;
    }
    &-list:not(.custom-image-list_none_image) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    &-list {
      gap: 16px;
      .custom-image_upload_item {
        position: relative;
        width: 100%;
        overflow: hidden;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .custom-item-upload-input {
          position: absolute;
          margin: 0;
          padding: 0;
          outline: none;
          opacity: 0;
          cursor: pointer;
          border-radius: 3px;
          z-index: 2;
          background-color: rgba(0, 0, 0, 0.05);
        }

        .custom-add_more_icon {
          max-height: 160px;
          img {
            max-height: 160px;
          }
        }
        // .custom-add_more_text {
        //   background: transparent;
        //   border-radius: 0.1875rem;
        //   color: #212121;
        //   cursor: pointer;
        //   font-size: 0.75rem;
        //   font-weight: 600;
        //   line-height: 1;
        //   padding: 0.25rem 0.375rem;
        //   position: relative;
        //   width: 14px;
        //   height: 14px;
        // }
        .custom-image_wrap_image_item {
          align-items: center;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          overflow: hidden;
          transform: translateZ(0);
          width: 100%;
          .custom-image_item {
            position: absolute;
            max-height: 100%;
            max-width: 100%;
          }
        }
        &:hover:not(.custom-image_upload_button) {
          // background-color: #fafbfb;
          // border-color: #458fff;
          .custom-background-item-blur {
            display: block;
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            border-radius: 3px;
            background-color: #252525d9;

            .custom-delete-label-isShow {
              margin: auto;
              height: 105px;
              text-overflow: ellipsis;
              .filename-wrap {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;

                p {
                  color: #fff;
                  margin: auto;
                  font-size: 12px;
                  font-weight: 600;
                  margin-bottom: 4px;
                  word-break: break-all;
                }
              }
              p {
                color: #fff;
                margin: auto;
                font-size: 12px;
                line-height: 20px;
                text-overflow: ellipsis;
                color: #aaaaaa;
              }
            }
            .custom-delete_confirm {
              display: flex;

              .custom-delete-label {
                color: #fff;
                margin: 12px 0px 0px 12px;
                font-size: 14px;
              }

              .custom-delete-action {
                display: flex;
                flex-direction: row;
                position: absolute;
                bottom: 0;
                right: 0;
              }
            }
            .custom-delete_item {
              height: 24px;
              width: 24px;
              position: absolute;
              bottom: 0;
              right: 0;
              cursor: pointer;
              margin-right: 10px;
              margin-bottom: 10px;
              // color: #43464b;
              font-size: 14px;
              font-weight: 500;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                background-color: #f4433633;
                // svg {
                //   color: #f9f9f9;
                // }
              }
            }
          }
        }

        &img {
          object-fit: fill;
        }
        .custom-background-item-blur {
          display: none;
        }
        .overflow-hidden:after {
          content: '';
          display: block;
          padding-bottom: 100%;
          width: 100%;
        }
      }
      .custom-image_upload_button {
        border: 1px solid #eeeeee;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }
    .custom-image-list_none_image {
      .custom-image_upload_button {
        min-height: 31rem;
      }
    }
  }
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1000px 0;
  }
}

.custom-loading {
  background-image: linear-gradient(90deg, #e4e4e4 0%, #f1f1f1 40%, #ededed 60%, #e4e4e4 100%);
  background-position: 0px 0px;
  background-repeat: repeat;
  animation: animatedBackground 5s linear infinite;
}
</style>
