<script setup lang="ts">
import { SettingTitle } from '@gem/control-v2';
import { GReorder } from '@gem/uikit';
import { GIcon } from '@gem/icons';
import type { ChildItem } from '../types';
import type { ScreenType } from '@gem/common';
import { computed } from 'vue';

type ColumnOrderType = {
  childrens?: ChildItem[];
  orderItems?: {
    id: string;
    title: string;
  }[];
  currentScreen: ScreenType;
  isSupportResponsiveOrder: boolean;
};

const props = withDefaults(defineProps<ColumnOrderType>(), {
  isSupportResponsiveOrder: true,
});

const emit = defineEmits<{
  (e: 'onReOrder', data: { id?: string; title?: string }[]): void;
  (e: 'remove-item', uid: string): void;
  (e: 'copy-item', uid: string): void;
}>();

const canCopy = computed(() => {
  return props.currentScreen === 'desktop' && props.orderItems && props.orderItems.length < 6;
});
const canDelete = computed(() => {
  return props.currentScreen === 'desktop' && props.orderItems && props.orderItems.length > 1;
});

const getPosition = (id: string) => {
  return (props.childrens?.findIndex((item) => item.uid === id) ?? 0) + 1;
};

const onReOrder = (data: { id?: string; title?: string }[]) => {
  emit('onReOrder', data);
};

const handleRemoveItem = (uid: string) => {
  emit('remove-item', uid);
};

const handleCopyItem = (uid: string) => {
  emit('copy-item', uid);
};
</script>

<template>
  <div class="ml-auto flex flex-col gap-8">
    <SettingTitle :label="{ en: 'Column order' }" variant="secondary" />
    <GReorder :items="orderItems || []" item-gap="8px" @re-order="onReOrder">
      <template #default="{ item }">
        <div
          class="bg-dark-400 text-light-450 group-[.sortable-fallback]/sortable-item:bg-dark-200 text-12 group flex h-36 items-center gap-4 rounded-xl px-10 font-medium transition-all duration-200 hover:cursor-move group-[.sortable-ghost]/sortable-item:bg-neutral-700 group-[&:not(.dragging)]/sortable:hover:bg-neutral-700">
          <GIcon :size="20" name="polaris-drag-handle" />
          <div class="flex gap-4">
            <span>{{ item.title }} {{ getPosition(item.id) }}</span>
          </div>
          <div class="ml-auto hidden h-full cursor-default items-center gap-2 group-hover:inline-flex">
            <g-tooltip v-if="canCopy" placement="top">
              <button
                class="hover:bg-dark-150 flex h-32 w-32 items-center justify-center rounded-xl"
                @click.prevent="handleCopyItem(item.id)">
                <g-base-icon width="16px" name="duplicate-page" view-box="0 0 16 16"></g-base-icon>
              </button>
              <template #content>
                <div class="text-light-100 font-regular text-12">Duplicate column</div>
              </template>
            </g-tooltip>
            <g-tooltip placement="top">
              <button
                :disabled="!canDelete"
                class="hover:bg-dark-150 flex h-32 w-32 items-center justify-center rounded-xl"
                :class="{ 'text-text-dark-100 cursor-no-drop': !canDelete }"
                @click.prevent="handleRemoveItem(item.id)">
                <g-base-icon width="16px" name="trash" view-box="0 0 16 16"></g-base-icon>
              </button>
              <template #content>
                <div class="text-light-100 text-12 font-regular leading-5">Delete column</div>
              </template>
            </g-tooltip>
          </div>
        </div>
      </template>
    </GReorder>
  </div>
</template>
