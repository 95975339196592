import ElementSettings from './settingUI/ElementSettings.vue';
import SettingTitle from './settingUI/SettingTitle.vue';
import ComboSetting from './settingUI/ComboSetting.vue';
import ControlLayout from './settingUI/layout/ControlLayout.vue';
import ComboSettingLayout from './settingUI/layout/ComboSettingLayout.vue';
import PresetSettingLayout from './settingUI/layout/PresetSettingLayout.vue';
import Control from './settingUI/Control.vue';
import Select from './controls/base/Select.vue';

export {
  ElementSettings,
  SettingTitle,
  ComboSetting,
  ControlLayout,
  ComboSettingLayout,
  Control,
  PresetSettingLayout,
  Select,
};

export * from './controls/index';

export * from './settingUI/types';

export const CONTROL_TYPE_WITH_POPOVER_NUMBER = {
  1: [
    'color-picker-v2',
    'corner-v2',
    'padding-v2',
    'background-image',
    'background-video',
    'background-media',
    'radius-preset',
    'image',
  ],
  2: ['border', 'border-v2'],
};
