<script setup lang="ts">
import {
  ComboSettingLayout,
  CONTROL_TYPE_WITH_POPOVER_NUMBER,
  type ControlChangeValueType,
  type SettingUIControl,
} from '@gem/control-v2';
import ControlSettings from './ControlSettings.vue';
import { computed, ref } from 'vue';
import useControlData from '../hooks/useControlData';
import useSettingSideBarStore from '../hooks/useSettingSideBarStore';
import { getColorFormGlobalStyle } from '@gem/common';

type ComboSettingProps = {
  data: SettingUIControl;
};

const emit = defineEmits<{
  (event: 'controlChange', value: ControlChangeValueType): void;
  (event: 'controlOnChange', value: ControlChangeValueType): void;
}>();

const modifyValue = (controlId: string, val?: any) => {
  return {
    val,
    controlId,
    screen: controlProps.value?.screen,
    state: props.data.setting?.state,
    default: controlProps?.value?.default,
    controlType: props.data.mapTo?.control.type,
    controlChangeTrigger: props.data.controlChangeTrigger,
    tabID: controlProps?.value?.tabId,
    devices: controlProps?.value?.devices,
    linkWithSetting: controlProps?.value?.linkWithSetting,
  };
};

const props = defineProps<ComboSettingProps>();

const settingSideBarStore = useSettingSideBarStore();

const comboIcon = ref(props.data.iconName);
const linkWithSettingValue = ref(getContentByControlID(props.data.getValueFromSettingID));

const hasSettingID = computed(() => props.data.setting?.id);
const controlIDGetContent = computed(() => props.data.getValueFromSettingID);
const defaultValueWhenClear = computed(() => props.data.defaultValueWhenClear);
const fixedValue = computed(() => props.data.fixedValue);
const isFullWidth = computed(() =>
  props.data?.options?.fullWidth === undefined ? true : props.data?.options?.fullWidth,
);

const controlData = computed(() => props.data);
const { controlProps } = useControlData({ controlData: controlData });
const isEnable = computed(() => controlProps?.value?.value);

const contentDisplay = computed(() => {
  if (hasSettingID.value) return isEnable.value ? fixedValue.value : '';
  if (props.data.comboType === 'color') {
    return getColorFormGlobalStyle(linkWithSettingValue.value, settingSideBarStore.globalStyles);
  }
  return linkWithSettingValue.value ?? fixedValue.value;
});

function getContentByControlID(controlID?: string) {
  const control = props.data.controls?.find((item) => item.mapTo?.control?.id === controlID)?.mapTo?.control;
  return control?.default;
}

const handelControlChange = (data: ControlChangeValueType) => {
  if (data.controlId === controlIDGetContent.value) {
    linkWithSettingValue.value = data.val;
  }
  emit('controlChange', data);
};

const handelControlOnChange = (data: ControlChangeValueType) => {
  if (data.controlId === controlIDGetContent.value) {
    linkWithSettingValue.value = data.val;
  }
  emit('controlOnChange', data);
};

const handleClear = () => {
  if (props.data.setting?.id) {
    handelControlChange(modifyValue(props.data.setting?.id, false));
  } else {
    linkWithSettingValue.value = undefined;
    if (controlIDGetContent.value) {
      handelControlChange(modifyValue(controlIDGetContent.value || '', defaultValueWhenClear.value));
    }
  }
};

const handleEnableCombo = () => {
  if (props.data.setting?.id && !isEnable.value) {
    handelControlChange(modifyValue(props.data.setting?.id, true));
  }
};

const totalPopover = computed(() => {
  let total = 0;
  function loopControl(controls: SettingUIControl[]) {
    if (controls?.length) {
      total++;
      controls.forEach((item) => {
        if (item.type === 'combo') total++;
        if (CONTROL_TYPE_WITH_POPOVER_NUMBER[1].includes(item.mapTo?.control.type || '')) total += 1;
        if (CONTROL_TYPE_WITH_POPOVER_NUMBER[2].includes(item.mapTo?.control.type || '')) total += 2;
        loopControl(item.controls || []);
      });
    }
  }
  if (props.data.controls?.length) {
    loopControl(props.data.controls);
  }
  return total;
});
</script>

<template>
  <div data-test="combo-setting">
    <ComboSettingLayout
      :id="controlProps?.id"
      :label="data?.label"
      :is-full-width="isFullWidth"
      :help="data.help"
      :level="data.level || 1"
      :total-popover="totalPopover"
      :placeholder="data.placeholder"
      :popover-label="data.popoverLabel"
      :combo-icon="contentDisplay ? comboIcon : undefined"
      :combo-color="data.comboType === 'color' ? contentDisplay : undefined"
      :combo-image="data.comboType === 'image' ? contentDisplay : undefined"
      :content-display="contentDisplay?.replace('#', '')"
      :is-hide-clear="data?.isHideClear"
      :combo-i-d="data?.comboID || ''"
      :combo-parent-i-d="data?.comboParentID || ''"
      :combo-root-parent-i-d="data?.comboRootParentID || ''"
      data-test="ComboSettingLayout"
      @click="handleEnableCombo"
      @clear="handleClear">
      <ControlSettings
        v-if="data.controls?.length"
        :controls="data.controls"
        :total-popover="totalPopover"
        @control-change="handelControlChange"
        @control-on-change="handelControlOnChange" />
    </ComboSettingLayout>
  </div>
</template>
