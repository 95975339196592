<script setup lang="ts">
import type { PaddingProps, PaddingType } from '@gem/control-v2';
import { ComboSettingLayout, ControlLayout } from '@gem/control-v2';
import { GButton } from '@gem/uikit-v2';
import SpacingInput from '../base/SpacingInput.vue';
import { usePaddingSetting } from './usePaddingSetting';

const props = defineProps<PaddingProps>();

const emit = defineEmits<{
  (e: 'controlChange', value?: PaddingType): void;
  (e: 'controlOnChange', value?: PaddingType): void;
  (e: 'change-link', value: boolean): void;
  (e: 'edit-global-style'): void;
}>();

const {
  val,
  isLinked,
  isFocus,
  paddingTopVal,
  isZeroValue,
  displayComboValue,
  positionOptions,
  onChangeInput,
  changeInput,
  handleSetDefaultValue,
  handleLinked,
  handleClearCorner,
  isDisableInput,
} = usePaddingSetting(props, emit);
</script>
<template>
  <div>
    <ComboSettingLayout
      :id="id"
      placeholder="Add..."
      :is-parent="true"
      :label="{ en: 'Padding' }"
      :show-label="false"
      :is-full-width="true"
      :content-display="displayComboValue"
      :combo-icon="val?.top && !isZeroValue ? 'gp-padding' : undefined"
      :level="level || 0"
      :combo-i-d="comboID || ''"
      :combo-parent-i-d="comboParentID || ''"
      :combo-root-parent-i-d="comboRootParentID || ''"
      @click="handleSetDefaultValue"
      @clear="handleClearCorner">
      <ControlLayout layout="vertical">
        <template #control>
          <div class="flex w-full flex-col">
            <div class="relative grid w-full select-none grid-cols-2 gap-y-8">
              <div
                v-for="(item, key) in positionOptions"
                :key="key"
                :class="{ 'col-span-2': ['top', 'bottom'].includes(key) }">
                <div
                  class="flex"
                  :class="{
                    'justify-center': ['top', 'bottom'].includes(key),
                    'justify-end': key === 'right',
                  }">
                  <div class="w-[84px]">
                    <SpacingInput
                      :id="key"
                      ref="inputSearch"
                      :value="isLinked ? paddingTopVal : val?.[key]"
                      :options="globalSpacingValues"
                      :is-focus="isFocus && key === 'top'"
                      :is-disable="isDisableInput(key)"
                      :is-padding="true"
                      @on-click-sub-action="() => emit('edit-global-style')"
                      @control-on-change="(id: string, value?: string) => onChangeInput(id, value)"
                      @control-change="(id: string, value?: string) => changeInput(id, value)" />
                  </div>
                </div>
              </div>
              <div class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <GButton
                  type="ghost"
                  :active="true"
                  size="small"
                  :only-icon="isLinked ? 'gp-padding-linked' : 'gp-padding-separate'"
                  @click="handleLinked" />
              </div>
            </div>
          </div>
        </template>
      </ControlLayout>
    </ComboSettingLayout>
  </div>
</template>
