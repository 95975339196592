type InputValidateProps = {
  max?: number;
  min?: number;
  defaultValue?: string;
};
export const useInputValidateValue = (props: InputValidateProps) => {
  const modifyValueByMinMax = (value?: string) => {
    if (!value || props.defaultValue) return value;
    const numberValue = parseInt(value ?? '0');
    if (props.min !== undefined && numberValue < props.min) return props.min.toString();
    if (props.max !== undefined && numberValue > props.max) return props.max.toString();
    return value;
  };

  const isNoModifyValue = (value?: string) => {
    return value && ['custom', 'mix', 'default'].includes(value.toLowerCase());
  };
  return {
    modifyValueByMinMax,
    isNoModifyValue,
  };
};
