<script lang="ts" setup>
import { ref } from 'vue';
import Segment from '../base/Segment.vue';
import Select from '../base/Select.vue';
import { ComboSettingLayout, ControlLayout } from '@gem/control-v2';
import { GInput, GButton } from '@gem/uikit-v2';
import { GIcon, GSvg } from '@gem/icons';
import { usePickLink } from './hooks/usePickLink';
import type { PickLinkProps } from './types';

const props = defineProps<PickLinkProps>();

const emit = defineEmits<{
  (e: 'control-search', value?: string): void;
  (e: 'control-on-change', controlId?: string | number, value?: any): void;
  (e: 'control-change', controlId?: string | number, value?: any): void;
  (e: 'control-focus', controlId?: string | number, value?: any): void;
  (e: 'control-blur', controlId?: string | number, value?: any): void;
  (e: 'set-tab-selected', tab?: any): void;
  (e: 'showMore'): void;
  (e: 'create-popup'): void;
}>();

const {
  val,
  comboDisplay,
  newTabOptions,
  followingOptions,
  pickLinkTypeList,
  pageTypesOptions,
  isEnableMoreSetting,
  searchVal,
  refInputSearch,
  infinityScroll,
  isValidEmail,
  pageLinkList,
  popupList,
  dataMailto,
  activePageTypeOption,
  typeLink,
  isTypeOpenPage,
  apiLoading,
  createPopup,
  changeMoreSettings,
  handleChangeType,
  changePageUrl,
  changeLink,
  onEnterSearchBox,
  onInputSearchBox,
  onValidateEmail,
  changePageType,
  handleClearCombo,
  onClearSearchBox,
  changeEmailLink,
  isActivePageItem,
  onOpenPageLink,
} = usePickLink(props, emit);

const collapsed = ref(true);
</script>
<template>
  <div class="gemx-control">
    <div class="flex flex-col gap-16">
      <ControlLayout :label="customLabel ?? { en: 'After click' }">
        <template #control>
          <Select id="type" :options="pickLinkTypeList" :value="typeLink" @control-change="handleChangeType" />
        </template>
      </ControlLayout>
      <div
        v-if="typeLink === 'open-page' || typeLink === 'scroll-to'"
        class="flex items-center justify-between gap-16"
        data-test="editor-control-page-link">
        <ControlLayout :label="comboDisplay.title" :help="comboDisplay.helpTitle">
          <template #control>
            <ComboSettingLayout
              placeholder="Add..."
              :label="comboDisplay.label"
              :show-label="false"
              :combo-icon="value?.link ? 'polaris-edit' : undefined"
              :content-display="comboDisplay.display"
              :is-full-width="true"
              :level="level || 0"
              :combo-i-d="comboID || ''"
              :combo-parent-i-d="comboParentID || ''"
              :combo-root-parent-i-d="comboRootParentID || ''"
              @close="validateSearchBox"
              @clear="handleClearCombo"
              @click="comboDisplay.open">
              <template #default="{ close }">
                <div class="flex flex-col gap-12">
                  <div class="flex gap-12">
                    <GInput
                      ref="refInputSearch"
                      type="text"
                      :placeholder="`${isTypeOpenPage ? 'Insert link or search' : 'Search section on this page'}`"
                      prefix-icon="polaris-search"
                      :value="searchVal"
                      @enter="() => onEnterSearchBox(close)"
                      @blur="() => !searchVal && onClearSearchBox()"
                      @on-change="onInputSearchBox" />
                    <div v-if="searchVal" class="absolute right-20 top-4 flex items-center">
                      <GButton
                        type="ghost"
                        size="normal"
                        only-icon="polaris-x-circle"
                        icon-view-box="0 0 14 14"
                        @click.stop="onClearSearchBox"></GButton>
                    </div>
                  </div>
                  <Select
                    v-if="isTypeOpenPage"
                    id="pageType"
                    :is-full-width="true"
                    :options="pageTypesOptions"
                    :value="activePageTypeOption"
                    blank-text="Custom url"
                    @control-change="(_: any, val: any) => changePageType(val)" />
                  <perfect-scrollbar ref="infinityScroll" class="h-full" :options="{ suppressScrollX: true }">
                    <div class="max-h-[348px] min-h-[160px]">
                      <div v-if="apiLoading" class="flex h-full w-full items-center justify-center">
                        <GIcon name="gp-loading" :spin-infinity="true" />
                      </div>
                      <template v-else>
                        <div
                          v-if="pageLinkList?.length === 0"
                          class="flex h-full min-h-[250px] flex-col items-center justify-center gap-8">
                          <GSvg name="gp-no-result" :width="80" :height="80" />
                          <p class="text-text-dark-100 text-12 text-center font-semibold leading-5">
                            No page match your search.<br />
                            Try another keyword or insert link
                          </p>
                        </div>
                        <template v-else>
                          <template v-for="item in pageLinkList" :key="item.id">
                            <div
                              class="text-12 hover:bg-dark-250 group relative flex h-36 cursor-pointer items-center justify-between gap-8 rounded-xl px-8"
                              @click="() => (isTypeOpenPage ? changePageUrl(item.handle) : changeLink(item.id))">
                              <div class="flex">
                                <div class="min-w-[28px]">
                                  <div
                                    v-if="isActivePageItem(item.handle)"
                                    class="flex h-[20px] items-center justify-center">
                                    <GSvg name="gp-checkmark" :width="20" :height="20" />
                                  </div>
                                </div>
                                <p :class="[`text-text-dark-${isTypeOpenPage ? '300' : '500'}`, 'truncate']">
                                  {{ item.title }}
                                </p>
                              </div>
                              <p v-if="typeLink === 'scroll-to'" class="text-text-dark-300">
                                {{ `${item.id === '#scroll-to-top' ? '' : ` ID:`} ${item.id}` }}
                              </p>
                              <div
                                class="bg-dark-250 invisible absolute top-0 right-0 flex h-full items-center justify-center rounded-xl px-8 group-hover:visible">
                                <GButton
                                  type="link"
                                  size="normal"
                                  icon-view-box="0 0 14 14"
                                  @click.stop="onOpenPageLink(item.handle)">
                                  View</GButton
                                >
                              </div>
                            </div>
                          </template></template
                        ></template
                      >
                    </div></perfect-scrollbar
                  >
                </div>
              </template>
            </ComboSettingLayout>
          </template>
        </ControlLayout>
      </div>
      <div
        v-if="typeLink === 'open-popup'"
        class="flex items-center justify-between gap-16"
        data-test="editor-control-page-link">
        <ControlLayout :label="{ en: 'Popup' }">
          <template #control>
            <div v-if="popupList?.length === 0" class="flex flex-col gap-8">
              <p class="text-text-dark-500 text-12 border-dark-200 border-b-[1px] pb-8">
                {{ `You don’t have any popup` }}
              </p>
              <GButton type="link" size="small" @click="createPopup">Create Popup</GButton>
            </div>
            <template v-else>
              <Select
                id="link"
                :options="popupList"
                :value="value?.link"
                disable-default
                blank-text="Choose one"
                @control-change="(_: any, value: string) => changeLink(value)" />
            </template>
          </template>
        </ControlLayout>
      </div>
      <template v-if="typeLink === 'send-email'">
        <ControlLayout :label="{ en: 'Email Address' }">
          <template #control>
            <GInput
              type="text"
              placeholder="chris@gempages.help"
              :value="dataMailto.email"
              @on-change="(value: string) => onValidateEmail(value)"
              @click-out-side="(value: string) => changeEmailLink('email', value)" />
            <div v-if="!isValidEmail" class="mt-8 flex items-center justify-center gap-8">
              <GSvg name="gp-status-error-filled" :width="20" :height="20" />
              <p class="text-12 text-red-200">Invalid email format</p>
            </div>
          </template>
        </ControlLayout>
        <ControlLayout :label="{ en: 'Subject' }">
          <template #control>
            <GInput
              type="text"
              placeholder="Eg: Need support"
              :value="dataMailto.subject"
              @click-out-side="(value: string) => changeEmailLink('subject', value)" />
          </template>
        </ControlLayout>
        <ControlLayout :label="{ en: 'Messages' }">
          <template #control>
            <GInput
              type="text"
              placeholder="Eg: Email content"
              :value="dataMailto.content"
              @click-out-side="(value: string) => changeEmailLink('content', value)" />
          </template> </ControlLayout
      ></template>
      <div
        v-if="typeLink === 'call-phone'"
        class="flex items-center justify-between gap-16"
        data-test="editor-control-page-link">
        <ControlLayout
          :label="{ en: 'Phone number' }"
          :help="{ content: 'People who reach your site on mobile or tablet can click this to call you ' }">
          <template #control>
            <GInput
              type="number"
              placeholder="Eg: 0123456789"
              :min="0"
              :value="val?.link?.replace('tel:', '')"
              @click-out-side="(value: string) => changeLink(`tel:${value}`)" />
          </template>
        </ControlLayout>
      </div>
      <template v-if="isEnableMoreSetting && !collapsed">
        <ControlLayout :label="{ en: 'Open new tab' }">
          <template #control>
            <Segment
              id="target"
              class="!w-[140px]"
              :value="val?.target ?? '_self'"
              :options="newTabOptions"
              @control-change="changeMoreSettings">
            </Segment>
          </template>
        </ControlLayout>
        <ControlLayout
          :label="{ en: 'Following Link' }"
          :help="{ content: 'Do not allow search engine to follow through this link' }">
          <template #control
            ><Segment
              id="noFollow"
              class="!w-[140px]"
              :value="!!val?.noFollow"
              :options="followingOptions"
              @control-change="changeMoreSettings">
            </Segment>
          </template> </ControlLayout
      ></template>
      <GButton
        v-if="isEnableMoreSetting"
        data-test="editor-control-show-more-btn"
        type="secondary"
        size="medium"
        button-width="full"
        icon-view-box="0 0 16 16"
        :icon-after="collapsed ? 'polaris-chevron-down' : 'polaris-chevron-up'"
        @click="collapsed = !collapsed">
        {{ collapsed ? 'Show more' : 'Show less' }}
      </GButton>
    </div>
  </div>
</template>
