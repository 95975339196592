<script lang="ts" setup>
import { computed, ref, watch } from 'vue';

import { GButton, GInput } from '@gem/uikit-v2';
import { Color } from '../Color';
import { TRANSPARENT_COLOR } from '../helpers';
import type { THEME_DARK, THEME_LIGHT } from '../const';
import type { ColorType } from '@gem/common';
import { isValidHexWithoutPrefix, isValidColorFormat, lightenDarkenColor, getHexColorThreeDigit } from '@gem/common';

const props = defineProps<{
  theme?: typeof THEME_DARK | typeof THEME_LIGHT;
  value?: ColorType | string;
  showTransparentColor: boolean;
  openColorPicker?: boolean;
}>();

const emits = defineEmits<{
  (e: 'toggleColorPicker'): void;
  (e: 'change', color: ColorType | string): void;
  (e: 'onChange', color: ColorType | string): void;
}>();

const colorInput = ref();
const isInputFocus = ref(false);
const isInputHover = ref(false);
const color = ref(Color.fromString(props.value));
const noHashtagHexColor = ref(
  props.value && props.value !== TRANSPARENT_COLOR ? color.value.getHexColor()?.replace('#', '') : '',
);

const selectAllFocus = (e: FocusEvent) => {
  isInputFocus.value = true;
  (e.target as HTMLInputElement).select();
};

const changeTagHexColor = () => {
  noHashtagHexColor.value = color.value?.getHexColor()?.replace('#', '');
};

const handleEmit = (type: 'change' | 'onChange') => {
  changeTagHexColor();
  const newValue = color.value.getColor();
  if (!newValue) return;
  if (type === 'change') emits('change', newValue);
  else {
    emits('onChange', newValue);
  }
};

const handleOnChangeHex = (value?: string) => {
  if (!value) return;
  if (getHexColorThreeDigit(value)) {
    changeColorOnThreeDigitHex(value);
    emits('onChange', color.value.getColor());
    return;
  } else {
    if (isValidHexWithoutPrefix(value)) {
      const opacity = color.value.a;
      color.value = Color.fromString('#' + value);
      color.value.setAlpha(opacity);
    } else if (isValidColorFormat(value)) {
      color.value = Color.fromString(value);
    }
  }
  handleEmit('onChange');
};

const handleOnChangeAlpha = (value?: string) => {
  const newValue = Number(Math.max(0, Math.min(100, Number(value)) / 100).toFixed(2));
  if (newValue >= 0 && newValue <= 1) {
    color.value.setAlpha(newValue);
    handleEmit('onChange');
  }
};

const browserSupportEyeDropper = computed(() => window?.EyeDropper);
declare global {
  interface Window {
    EyeDropper: any;
  }
}

type EyeDropperConstructor = new () => EyeDropperInterface;

interface ColorSelectionOptions {
  signal: AbortSignal;
}

interface ColorSelectionResult {
  sRGBHex: string;
}

interface EyeDropperInterface extends EyeDropperConstructor {
  open: (options?: ColorSelectionOptions) => Promise<ColorSelectionResult>;
}

declare let EyeDropper: {
  prototype: EyeDropperInterface;
  new (): EyeDropperInterface;
};

const onOpenEyeDropper = () => {
  const eyeDropper = new EyeDropper();
  eyeDropper
    .open()
    .then((result: any) => {
      color.value = Color.fromString(result.sRGBHex);
      handleEmit('change');
    })
    .catch((e: any) => {
      console.error(e);
    });
};

const changeColorOnThreeDigitHex = (value: string) => {
  noHashtagHexColor.value = value;
  color.value = Color.fromString(value);
};

watch(
  () => props.value,
  (newValue) => {
    color.value = Color.fromString(newValue);
    if (getHexColorThreeDigit(noHashtagHexColor.value)) {
      return;
    }
    changeTagHexColor();
  },
);
</script>

<template>
  <div class="flex gap-8">
    <GButton type="tertiary" size="medium" class="h-36 !w-36 rounded-xl !p-0" @click="emits('toggleColorPicker')">
      <div
        class="content !h-20 !w-20"
        :class="{
          'hover:border-light-450': showTransparentColor || color.a === 0,
        }">
        <div v-if="!showTransparentColor && color.a !== 0" class="relative h-20 w-20">
          <div
            class="picked-color !h-20 !w-20 rounded-[6px] border"
            :style="{
              backgroundColor: color.getColor(),
              'border-color': lightenDarkenColor(color.getColor()),
            }" />
        </div>
        <img
          v-else
          src="../../../assets/color-transparent-preview.png"
          class="h-20 w-20"
          alt="color-transparent-preview" />
      </div>
    </GButton>
    <div
      class="relative flex flex-1"
      @mouseover="() => (isInputHover = true)"
      @mouseleave="() => (isInputHover = false)">
      <GInput
        ref="colorInput"
        :value="noHashtagHexColor"
        no-border="right"
        @on-change="handleOnChangeHex"
        @change="() => handleEmit('change')" />
      <GInput
        :value="Math.round(color.a * 100)"
        no-border="left"
        max-width="55px"
        @on-change="handleOnChangeAlpha"
        @change="() => handleEmit('change')"
        @focus="selectAllFocus"
        @blur="() => (isInputFocus = false)" />
      <span class="text-12 text-light-200 absolute right-8 top-[10px]">%</span>
    </div>
    <GButton
      v-if="browserSupportEyeDropper"
      only-icon="polaris-eye-dropper"
      type="tertiary"
      size="medium"
      class="w-36 rounded-xl p-8"
      @click.stop="onOpenEyeDropper" />
  </div>
</template>
