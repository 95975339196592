<script setup lang="ts">
import type { CornerSettingProps } from './types';
import { ComboSettingLayout, ControlLayout } from '@gem/control-v2';
import type { ColorSuggestions } from '@gem/common';
import { useCornerSettings } from './hooks/useCornerSettings';
import CornerRadius from './CornerRadius.vue';
import { nextTick, ref } from 'vue';

const props = defineProps<CornerSettingProps>();

const emit = defineEmits<{
  (e: 'controlChange', controlId?: string, value?: CornerSettingProps['value']): void;
  (e: 'controlOnChange', controlId?: string, value?: CornerSettingProps['value']): void;
  (e: 'updateColorSuggestion', value?: ColorSuggestions): void;
}>();

const isFocus = ref(false);

const {
  cornerValues,
  displayComboValue,
  displayComboIcon,
  options,
  handleUpdateSetting,
  handleSetDefaultValue,
  handleClearCorner,
} = useCornerSettings(props, emit);

const handleChangeCornerType = (type: 'controlChange' | 'controlOnChange', value?: boolean) => {
  let data = { ...cornerValues.value, radiusType: value ? 'rounded' : 'custom' };
  if (value) {
    const valueChange = cornerValues.value?.['btlr'] ?? 0 + 'px';
    data = {
      bblr: valueChange,
      bbrr: valueChange,
      btlr: valueChange,
      btrr: valueChange,
      radiusType: 'rounded',
    };
  }
  handleUpdateSetting(type, data as any);
};

const handleClick = () => {
  handleSetDefaultValue();
  isFocus.value = false;
  nextTick(() => {
    isFocus.value = true;
  });
};
</script>
<template>
  <div>
    <ComboSettingLayout
      :id="id"
      placeholder="Add..."
      :is-parent="true"
      :label="{ en: 'Corner' }"
      :show-label="false"
      :is-full-width="true"
      :content-display="displayComboValue"
      :combo-icon="displayComboIcon"
      :level="level || 0"
      :combo-i-d="comboID || ''"
      :combo-parent-i-d="comboParentID || ''"
      :combo-root-parent-i-d="comboRootParentID || ''"
      @click="handleClick"
      @clear="handleClearCorner">
      <ControlLayout :label="{ en: 'Radius' }" layout="vertical">
        <template #control>
          <CornerRadius
            :value="cornerValues"
            :options="options"
            :is-focus="isFocus"
            @change-link="(value: any) => handleChangeCornerType('controlChange', value)"
            @control-change="( value: any) => handleUpdateSetting('controlChange', value)"
            @control-on-change="(value: any) => handleUpdateSetting('controlOnChange', value)" />
        </template>
      </ControlLayout>
    </ComboSettingLayout>
  </div>
</template>
