import type { ColorSuggestions, ShadowProps, ShadowType } from '@gem/common';
import { checkEmptyValue } from '@gem/common';
import { computed } from 'vue';
import type { ShadowSettingProps } from './types';

export const useBoxShadowSettings = (shadowSettingProps: ShadowSettingProps, emit: any) => {
  const isTextShadow = computed(() => shadowSettingProps?.type === 'text-shadow');

  const initialShadowValue: ShadowProps = {
    type: isTextShadow.value ? 'custom' : 'shadow-1',
    distance: isTextShadow.value ? '2px' : '4px',
    blur: isTextShadow.value ? '8px' : '4px',
    spread: '0px',
    color: isTextShadow.value ? 'rgba(18, 18, 18, 0.5)' : '#121212',
    angle: isTextShadow.value ? 135 : 45,
  };

  const emptyShadowValue: ShadowProps = {
    type: 'none',
  };

  const shadowPresets: {
    label: string;
    value: string;
    defaultValue?: ShadowProps;
    iconName?: string;
  }[] = [
    {
      value: 'shadow-1',
      label: 'Light',
      iconName: 'gp-shadow-light',
      defaultValue: initialShadowValue,
    },
    {
      value: 'shadow-2',
      label: 'Medium',
      iconName: 'gp-shadow-medium',
      defaultValue: {
        type: 'shadow-2',
        distance: '12px',
        blur: '12px',
        spread: '0px',
        color: '#121212',
        angle: 45,
      },
    },
    {
      value: 'shadow-3',
      label: 'Big',
      iconName: 'gp-shadow-hard',
      defaultValue: {
        type: 'shadow-3',
        distance: '20px',
        blur: '20px',
        spread: '0px',
        color: '#121212',
        angle: 45,
      },
    },
    {
      value: 'custom',
      label: 'Custom',
      iconName: 'gp-customize',
    },
  ];

  const val = computed(() => shadowSettingProps?.value);

  const defaultValue = computed(() => {
    return shadowSettingProps.compoDefaultValue || initialShadowValue;
  });

  const isCustomShadow = computed(() => {
    return val.value?.type === 'custom';
  });

  const isNoneShadow = computed(() => {
    return val.value?.type === 'none';
  });

  const displayComboValue = computed(() => {
    if (isCustomShadow.value) return val.value?.color ? val.value?.color?.replace('#', '') : 'Custom';

    return shadowPresets.find((item) => item.value === val.value?.type)?.label ?? '';
  });

  const selectedOption = computed(() => {
    return shadowPresets.find((item) => item.value === val.value?.type);
  });

  const displayComboIcon = computed(() => {
    return selectedOption.value?.iconName;
  });

  const displayComboColor = computed(() => {
    return isCustomShadow.value ? val.value?.color : '';
  });

  const handleEmit = (data: ShadowProps | undefined, type: 'controlOnChange' | 'controlChange') => {
    if (type === 'controlChange') emit('controlChange', shadowSettingProps.id, data);
    else emit('controlOnChange', shadowSettingProps.id, data);
  };

  const handleUpdateSetting = (
    key: 'type' | 'color' | 'angle' | 'distance' | 'blur' | 'spread',
    type: 'controlOnChange' | 'controlChange',
    value?: string,
  ) => {
    if (!value) return;
    let data: ShadowProps = {};
    switch (key) {
      case 'type': {
        const defaultValueByType = shadowPresets.find((item) => item.value === value);
        data =
          defaultValueByType && defaultValueByType.defaultValue
            ? { ...defaultValueByType.defaultValue, color: val.value?.color ?? defaultValueByType.defaultValue.color }
            : { ...val.value, type: value as ShadowType };
        break;
      }
      case 'color':
        data = { ...val.value, color: value };
        break;
      case 'angle':
        data = { ...val.value, angle: value };
        break;
      case 'distance':
        data = { ...val.value, distance: value };
        break;
      case 'blur':
        data = { ...val.value, blur: value };
        break;
      case 'spread':
        data = { ...val.value, spread: value };
        break;
      default:
        break;
    }
    handleEmit(data, type);
  };

  const handleClearShadow = () => {
    handleEmit(emptyShadowValue, 'controlChange');
  };

  const handleSetDefaultValue = () => {
    if (val.value && !checkEmptyValue(val.value) && !isNoneShadow.value) return;

    handleEmit(defaultValue.value, 'controlChange');
  };

  const handleUpdateSuggestionColor = (value?: ColorSuggestions) => {
    emit('updateColorSuggestion', value);
  };

  return {
    isTextShadow,
    shadowValue: val,
    displayComboIcon,
    displayComboColor,
    displayComboValue,
    shadowPresets,
    isCustomShadow,
    handleUpdateSetting,
    handleClearShadow,
    handleSetDefaultValue,
    handleUpdateSuggestionColor,
  };
};
