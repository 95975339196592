<script lang="ts" setup>
import { ref, watch } from 'vue';
import { GButton, GInput } from '@gem/uikit-v2';
import { useDebounceFn } from '@vueuse/core';
import { DEFAULT_UNIT } from '@gem/common';

type PropsType = {
  id?: string;
  value?: number | string;
  placeholder?: string;
  readonly?: boolean;
  min?: number;
  max?: number;
  isOnlyAcceptInteger?: boolean;
  includeUnit?: 'px' | '%';
};

const props = withDefaults(defineProps<PropsType>(), {
  id: '',
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
  readonly: false,
  placeholder: '',
});

const emit = defineEmits<{
  (e: 'controlOnChange', controlId: string, value?: number | string): void;
  (e: 'controlChange', controlId: string, value?: number | string): void;
}>();

const val = ref<number | undefined>(props.value ? parseInt(props.value as any) : 0);
const previousInputValue = ref<number | undefined>(props.value ? parseInt(props.value as any) : 0);
const isFocus = ref(false);
const isInputChange = ref(false);

watch(
  () => props.value,
  (newPropValue) => {
    if (!newPropValue) {
      return;
    }
    val.value = parseInt(newPropValue as any) || props.min || previousInputValue.value || 0;
  },
);

const checkRangeValue = (value: number | undefined): number => {
  if (!value) return props.min ? props.min : 0;
  if (value > props.max) return props.max;
  if (value < props.min) return props.min;
  return value;
};

const controlClick = (change: any) => {
  if (isFocus.value) return;
  isInputChange.value = false;
  val.value += change;
  val.value = checkRangeValue(val.value);
  previousInputValue.value = val.value;
  handleEmit('controlOnChange');
  debouncedClickOnChange();
};

const debouncedClickOnChange = useDebounceFn(() => {
  handleEmit('controlChange');
}, 500);

const handleOnChange = (value: string) => {
  isInputChange.value = true;
  val.value = checkRangeValue(parseInt(value));
  handleEmit('controlOnChange');
};

const handleChange = () => {
  if (!isInputChange.value) return;
  val.value = checkRangeValue(val.value);
  previousInputValue.value = val.value;
  handleEmit('controlChange');
};

const handleEmit = (type: 'controlChange' | 'controlOnChange') => {
  let newValue = val.value?.toString();
  if (props.includeUnit && DEFAULT_UNIT.includes(props.includeUnit)) {
    newValue = `${newValue}${props.includeUnit}`;
  }
  if (type === 'controlChange') emit('controlChange', props.id, newValue);
  else emit('controlOnChange', props.id, newValue);
};
</script>

<template>
  <div
    data-test="editor-control-input-number"
    class="bg-dark-400 group rounded-xl border border-transparent"
    :class="{ 'border-primary-300': isFocus }">
    <div class="flex h-36 items-center justify-between rounded-xl" :class="{ 'group-hover:bg-dark-250': !isFocus }">
      <div>
        <GButton
          type="ghost"
          data-test="editor-control-button-number-down"
          only-icon="polaris-minus"
          size="medium"
          :disabled="readonly || Number(val) <= min"
          @click="controlClick(-1)" />
      </div>
      <GInput
        :value="val"
        :no-background="true"
        data-test="editor-control-input-number-field"
        align="center"
        type="number"
        no-border="all"
        @focus="() => (isFocus = true)"
        @focusout="() => (isFocus = false)"
        @click-out-side="handleChange"
        @on-change="handleOnChange" />
      <div>
        <GButton
          type="ghost"
          data-test="editor-control-button-number-down"
          only-icon="polaris-plus"
          size="medium"
          :disabled="readonly || Number(val) >= max"
          @click="controlClick(1)" />
      </div>
    </div>
  </div>
</template>
