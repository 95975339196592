<script setup lang="ts">
import type { LabelWithLang, SettingUIHelpType, LabelVariant } from '@gem/control-v2';
import Tip from './Tip.vue';
import type { ScreenType } from '@gem/common';
import DeviceSelectPopup from '../controls/device-select/DeviceSelectPopup.vue';
import useSettingSideBarStore from '../hooks/useSettingSideBarStore';
type SettingTitleProps = {
  label?: LabelWithLang;
  variant?: LabelVariant;
  help?: SettingUIHelpType;
  showDevices?: boolean;
  currentDevice?: ScreenType;
};

withDefaults(defineProps<SettingTitleProps>(), {
  variant: 'primary',
});

const settingSideBarStore = useSettingSideBarStore();

const handleChangeActiveDevice = (device: ScreenType) => {
  settingSideBarStore.setCurrentDevice(device);
};
</script>

<template>
  <div
    v-if="label"
    class="text-12 flex h-[32px] items-center justify-between gap-4"
    :class="{
      'text-light-100 font-medium': variant === 'primary',
      'font-semibold': variant === 'bold',
      'text-text-dark-300': variant === 'secondary',
    }">
    <div class="flex items-center gap-4">
      {{ label.en }}
      <DeviceSelectPopup v-if="showDevices" :current-screen="currentDevice" @change-screen="handleChangeActiveDevice" />
      <div v-if="help" class="flex items-center">
        <Tip v-if="help" :content="help.content" :button="help.button" :media="help.media" />
      </div>
    </div>
  </div>
</template>
