import { computed, ref, watch } from 'vue';
import type { GridArrangeProps } from '../types';

export const useGridArrange = (props?: GridArrangeProps, emit?: any) => {
  const getInitCols = () => {
    if (props?.cols && props?.cols.length === 2) return getColsValueSupportMigrateProductImage();
    return props?.cols;
  };
  const getColsValueSupportMigrateProductImage = () => {
    let first = props?.cols?.[0] ?? 0;
    let second = props?.cols?.[1] ?? 0;
    const totalCol = first + second;
    if (totalCol == 12) {
      return props?.cols;
    } else {
      const delta = first / second + 1;
      second = Math.round(12 / delta);
      first = 12 - second;
      return [first, second];
    }
  };
  const columns = ref(getInitCols() || []);
  const mainArea = ref<HTMLDivElement>();

  watch(
    () => props?.cols,
    (newVal) => {
      columns.value = newVal || [];
    },
  );

  const gridTemplateColumns = computed(() => {
    if (columns.value?.length) {
      return columns.value
        .map((item, index, arr) =>
          index === arr.length - 1 ? `${item}fr` : `${item}fr ${arr.length <= 4 ? '36px' : '4px'}`,
        )
        .join(' ');
    }
    return '1fr';
  });

  const onChangeCols = (index: number, position: number) => {
    const { min, max } = getPositionValue(columns.value, index);
    const currentPosition = sumArr(columns.value, index);
    if (currentPosition === position || position < min || position > max) return;
    const newCols = arrange(columns.value, index, position);
    columns.value = newCols;
    emit('control-on-change', newCols);
  };

  const changeCols = () => {
    emit('control-change', columns.value);
  };

  const add = (accumulator: number, a: number) => {
    return accumulator + a;
  };

  const sumArr = (arr: number[], index: number) => {
    return [...arr].slice(0, index + 1).reduce(add, 0);
  };

  const getPositionValue = (values: number[], index: number) => {
    const min = index === 0 ? 1 : sumArr(values, index - 1) + 1;
    const max = sumArr(values, index + 1);
    const diff = max - min;
    return {
      canEdit: diff > 1,
      min,
      max: max - 1,
    };
  };

  const arrange = (values: number[], index: number, pos: number) => {
    const { canEdit, min, max } = getPositionValue(values, index);
    if (!canEdit || min > pos || max < pos) return values;

    const current = sumArr(values, index);
    const left = values[index];
    const right = values[index + 1];
    const diff = pos - current;
    values[index] = left + diff;
    values[index + 1] = right - diff;
    return values;
  };

  /* *Check if a number is between the min and max range */
  const inRange = (num: number, max: number, min = 0) => {
    return num >= min && num <= max;
  };

  return {
    columns,
    mainArea,
    gridTemplateColumns,
    onChangeCols,
    changeCols,
    getPositionValue,
    arrange,
    sumArr,
    inRange,
  };
};
