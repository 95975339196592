import type { FontItem, FontName } from '@gem/common';
import type { TypographyFamily } from '@gem/control';
import { useGetFontWeightWithVariants } from './useGetFontWeightWithVariants';

export function isFontExist(allFonts: FontItem[], currentFontFamily?: string | TypographyFamily): boolean {
  return allFonts.some((font) => {
    if (typeof currentFontFamily === 'string') {
      return currentFontFamily === font?.family;
    }
    return currentFontFamily?.value === font?.family;
  });
}

export function useGetFontWeightOptions({
  fontBackup,
  themeFonts,
}: {
  fontBackup: FontItem | undefined;
  themeFonts?: Partial<Record<FontName, FontItem>>;
}) {
  const resolveFontValue = (fontValue: string): string => {
    if (fontValue?.includes('--g-theme-font')) {
      const typeValue = fontValue.replace('--g-theme-font-', '') as FontName;
      return themeFonts?.[typeValue]?.family || '';
    }
    return fontValue;
  };

  const getFontWeightOptions = (allFonts: FontItem[], currentFontFamily?: string | TypographyFamily) => {
    const font = allFonts?.find((item) => {
      if (typeof currentFontFamily === 'string') {
        return item.family === currentFontFamily;
      }

      return (
        resolveFontValue(item.family) === resolveFontValue(currentFontFamily?.value || '') &&
        item.type === currentFontFamily?.type
      );
    });

    const shouldUseBackupFont = !isFontExist(allFonts, currentFontFamily) && fontBackup;

    const variantsFont = (shouldUseBackupFont ? fontBackup.variants : font?.variants) ?? ['400'];

    return useGetFontWeightWithVariants(variantsFont);
  };

  return {
    getFontWeightOptions,
  };
}
