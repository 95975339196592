import type { ScreenType, TypographyV2Attrs, TypographyV2Props } from '@gem/common';
import { getResponsiveValueByScreen } from '@gem/common';
import { computed } from 'vue';
import type { TypoFormEditProps } from '../types';

export const useTypographyFormEdit = (typoFormEditProps: TypoFormEditProps, emit: any) => {
  const responsiveFields: (keyof TypographyV2Props | keyof TypographyV2Attrs)[] = [
    'fontSize',
    'lineHeight',
    'textAlign',
  ];

  const arrSearchKeyword = computed(() => typoFormEditProps.searchQuery?.toLowerCase().split(' ') || []);

  const isResultSearch = computed(() => {
    if (!typoFormEditProps.searchQuery) return false;
    const settingKeyword =
      'styles, font, size, color, font weight, line height, letter spacing, transform, align'.toLowerCase();
    return arrSearchKeyword.value.findIndex((item) => settingKeyword.includes(item)) > -1;
  });

  const fontWeightValue = computed(() => {
    if (typoFormEditProps.fontWeightOptions && typoFormEditProps.fontWeightOptions.length > 0) {
      const findFontWeight = typoFormEditProps.fontWeightOptions?.find(
        (item) => item.value === typoFormEditProps.value?.fontWeight,
      );

      return findFontWeight?.value || typoFormEditProps.fontWeightOptions[0].value;
    }
    return undefined;
  });

  const letterSpacing = computed(() => {
    if (!typoFormEditProps.value?.letterSpacing || typoFormEditProps.value?.letterSpacing === 'normal') return '0px';
    return typoFormEditProps.value?.letterSpacing;
  });

  const fontSize = computed(() => {
    const value = getResponsiveValueByScreen(typoFormEditProps.value?.fontSize, typoFormEditProps.currentScreen);
    return value ? parseFloat(value) : undefined;
  });

  const lightHeight = computed(() => {
    const value = getResponsiveValueByScreen(typoFormEditProps.value?.lineHeight, typoFormEditProps.currentScreen);
    return value;
  });

  const textAlign = computed(() => {
    const value = getResponsiveValueByScreen(typoFormEditProps.attrs?.textAlign, typoFormEditProps.currentScreen);
    return value;
  });

  const isShowSettingBySearch = (settingID: String) => {
    if (!isResultSearch.value) return true;
    return (
      isResultSearch.value && arrSearchKeyword.value.findIndex((item) => settingID.toLowerCase().includes(item)) > -1
    );
  };

  const emitChange = (
    name: string,
    value: any,
    args: { propType?: 'custom' | 'attrs'; type?: 'change' | 'onChange' },
  ) => {
    const propType = args.propType ?? 'custom';
    const type = args.type ?? 'change';
    if (name === 'fontSize') value = `${value}px`;
    if (responsiveFields.includes(name as keyof TypographyV2Props)) {
      const currentValue: any = typoFormEditProps.value?.[name as keyof TypographyV2Props];
      value = {
        ...currentValue,
        [typoFormEditProps.currentScreen as ScreenType]: value,
      };
    }

    switch (type) {
      case 'change':
        emit('change', name, value, propType);
        break;
      case 'onChange':
        emit('onChange', name, value, propType);
        break;
      default:
        break;
    }
  };

  const handleChange = (name: string, value: any) => {
    emitChange(name, value, { propType: 'custom', type: 'change' });
  };

  const handleChangeAttr = (name: string, value: any) => {
    emitChange(name, value, { propType: 'attrs', type: 'change' });
  };

  const onControlChange = (name: string, value?: any) => {
    emitChange(name, value, { propType: 'custom', type: 'onChange' });
  };

  const toggleProp = (name: keyof Pick<TypographyV2Attrs, 'bold' | 'italic' | 'underline'>) => {
    handleChangeAttr(name, !typoFormEditProps.attrs?.[name]);
  };
  const onSaveColor = (newColorList?: {}) => emit('saveColor', newColorList);
  return {
    responsiveFields,
    arrSearchKeyword,
    isResultSearch,
    fontWeightValue,
    letterSpacing,
    fontSize,
    lightHeight,
    textAlign,
    isShowSettingBySearch,
    onSaveColor,
    toggleProp,
    onControlChange,
    handleChangeAttr,
    handleChange,
  };
};
