<script setup lang="ts">
import type { Maybe } from '@gem/common';
import { GSvg } from '@gem/icons';

type PropTypes = {
  productDisplay: {
    title: string;
    image?: Maybe<string>;
    id: Maybe<string>;
  };
};
defineProps<PropTypes>();

const emit = defineEmits<{
  (e: 'goToProduct'): void;
}>();

const handleClick = () => {
  emit('goToProduct');
};
</script>

<template>
  <div class="flex flex-col gap-8" @click="handleClick">
    <g-tooltip placement="top" :is-teleport="true">
      <div
        class="bg-dark-400 hover:bg-dark-250 group relative flex h-[56px] w-full cursor-pointer items-center gap-[11px] overflow-hidden rounded-xl p-8">
        <img
          v-if="productDisplay?.image"
          class="rounded-medium aspect-square h-40 w-40 shrink-0 object-cover"
          :src="productDisplay?.image"
          alt="product feature img" />
        <div v-else class="rounded-medium aspect-square h-40 w-40 shrink-0 overflow-hidden">
          <GSvg name="gp-product-image-placeholder" />
        </div>
        <p class="text-light-450 font-regular text-12 line-clamp-2 self-center leading-[14px]">
          {{ productDisplay?.title }}
        </p>
      </div>
      <template #content> Go to Product element </template>
    </g-tooltip>
  </div>
</template>
