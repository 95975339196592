<script lang="ts" setup>
import { GSvg } from '@gem/icons';
import { computed } from 'vue';

type LayoutValue = {
  display?: string;
  cols?: number[];
};

withDefaults(
  defineProps<{
    value?: LayoutValue;
    maxCol?: number;
  }>(),
  {
    maxCol: 1,
  },
);

const emit = defineEmits<{
  (e: 'changeLayout', value: number): void;
}>();

const layouts = computed(() => {
  return [
    {
      col: 2,
      icon: `gp-product-layout-horizontal`,
    },
    {
      col: 1,
      icon: 'gp-product-layout-vertical',
    },
  ];
});

const handleChangeLayout = (cols: number) => {
  emit('changeLayout', cols);
};
</script>

<template>
  <slot></slot>
  <div class="grid w-full grid-cols-2 gap-8">
    <div
      v-for="item in layouts"
      :key="item.col"
      class="group/layout grid max-w-full cursor-pointer gap-[2px] rounded-xl border border-transparent"
      :class="{
        'border-primary-300': item.col == value?.cols?.length,
      }"
      @click="handleChangeLayout(item.col)">
      <div
        class="bg-dark-400 hover:bg-dark-250 flex h-[80px] items-center justify-center overflow-hidden rounded-xl p-[12px]">
        <GSvg :name="item.icon" />
      </div>
    </div>
  </div>
</template>
