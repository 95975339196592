import type { ColorProps, ColorType } from '@gem/common';
import { isColor } from '@gem/common';
import type { Ref } from 'vue';

import { computed } from 'vue';

const useSuggestedColor = (globalColors: Ref<any>, colorValue?: Ref<ColorType | string | undefined>) => {
  const options = computed<ColorProps>(() => {
    if (!colorValue?.value) {
      return {
        colorType: 'custom',
        color: '',
      };
    }

    if (isColor(colorValue?.value)) {
      return {
        colorType: 'custom',
        color: colorValue?.value,
      };
    }

    return {
      color: globalColors.value.find((col: any) => col.colorType === colorValue?.value)?.color,
      colorType: colorValue?.value as ColorType,
    };
  });

  return options;
};

export default useSuggestedColor;
