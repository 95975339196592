<script lang="ts" setup>
import type { GradientColor } from '../../types';
import { THEME_DARK } from '../../const';
import type { THEME_LIGHT } from '../../const';

import { onMounted, onUnmounted, ref } from 'vue';

import Previewer from './Previewer.vue';
import AnglePicker from './AnglePicker.vue';

withDefaults(
  defineProps<{
    theme?: typeof THEME_DARK | typeof THEME_LIGHT;
    gradientColor?: GradientColor;
  }>(),
  {
    theme: THEME_DARK,
  },
);

const emits = defineEmits<{
  (e: 'onRemovePoint'): void;
}>();

const gradientPicker = ref<HTMLElement | null>(null);
const displayWarningMessage = ref(false);
const delayHideWarningMessage = ref<NodeJS.Timeout | undefined>();
const showWarningMessage = () => {
  displayWarningMessage.value = true;
  clearTimeout(delayHideWarningMessage.value);
  delayHideWarningMessage.value = setTimeout(() => {
    displayWarningMessage.value = false;
  }, 3000);
};

const deleteDragItem = (e: KeyboardEvent) => {
  e.stopPropagation();
  if (e.key === 'Delete' || e.key === 'Backspace') {
    emits('onRemovePoint');
  }
};

onMounted(() => {
  document.addEventListener('keydown', deleteDragItem);
});
onUnmounted(() => {
  document.removeEventListener('keydown', deleteDragItem);
});
</script>

<template>
  <div ref="gradientPicker" class="relative flex items-center justify-between pl-10">
    <div v-if="gradientColor" class="relative -mt-8 w-[calc(100%-85px)]">
      <Previewer
        :theme="theme"
        type="linear"
        :points="gradientColor.points"
        :angle="gradientColor?.angle"
        @show-warning-message="showWarningMessage" />
    </div>
    <AnglePicker :theme="theme" class="ml-16" :degrees="gradientColor?.angle" />
    <transition name="fade">
      <p v-if="displayWarningMessage" class="warning-message text-12 text-red-250 absolute left-0 -bottom-12 leading-5">
        You can add maximum 5 colors
      </p>
    </transition>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
