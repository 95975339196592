import { isColor } from '@gem/common';
import { useGlobalStyleData } from '../../../hooks/useGlobalStyleData';
import { computed } from 'vue';
import type { BorderSettingProps } from '../types';

export const useBorderColor = (borderSettingProps: BorderSettingProps) => {
  const settingID = computed(() => borderSettingProps.id);
  const { globalStyleColorList } = useGlobalStyleData(borderSettingProps.globalStyle);
  const borderIds = ['borderBtn', 'headerBorder', 'borderBg', 'borderState', 'optionBorder', 'oosBorder'];
  const mapColorTypeToBorderId = (id: string) => {
    switch (id) {
      case 'borderBtn':
      case 'oosBorder':
        return 'brand';
      case 'borderBg':
      case 'borderState':
        return 'bg-2';
      case 'optionBorder':
        return 'bg-3';
      default:
        return '';
    }
  };
  const modifyChangeColorBySettingID = (newColor?: string) => {
    let changedColor = newColor;
    if (!newColor && borderIds.includes(settingID.value)) {
      const globalBgColor = globalStyleColorList.value.find(
        (val) => val.colorType === mapColorTypeToBorderId(settingID.value),
      )?.color;
      if (!globalBgColor) changedColor = newColor;
      else {
        changedColor = globalBgColor === '#000000' ? '#888888' : '#000000';
      }
    }
    if (newColor && !isColor(newColor)) {
      changedColor = globalStyleColorList.value.find((col) => col.colorType === newColor)?.color;
    }
    return changedColor;
  };

  return {
    modifyChangeColorBySettingID,
  };
};
